import React from 'react';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/browser';

import qs from 'qs';
import { validateEmail } from 'Utils';

import { Input, Button as UIButton, EmptyButton } from '../../UI';

const Title = styled.h2`
  font-size: 18px;
  font-weight: 300;

  @media screen and (min-width: 1200px) {
    font-size: 31px;
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 8px;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;

const Form = styled.form`
  margin-top: 20px;

  @media screen and (min-width: 1200px) {
    margin-top: 60px;
  }
`;

const Buttons = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Button = styled(UIButton)`
  flex-basis: 100%;
  text-align: center;

  @media screen and (min-width: 1200px) {
    flex-basis: 50%;
  }
`;

const ButtonReset = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.color11};
  font-size: 11px;
  font-weight: 500;
  flex-basis: 100%;
  text-align: center;
  outline: none;
  cursor: pointer;
  margin-top: 20px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    flex-basis: 50%;
    margin: 0;
  }
`;

const Auth = styled.div`
  padding: 0 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.color4};

  @media screen and (min-width: 1200px) {
    padding: 0 40px;
    padding-bottom: 40px;
  }
`;

const Register = styled.div`
  padding: 40px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 35%;
`;

const MiniTitle = styled.h4`
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 40px;
`;

class PopupLogin extends React.Component {
  state = {
    saveData: false,
    email: '',
    password: '',
    loading: false,
  };

  sendError = (extra, title) => {
    let eventID = false;

    Sentry.withScope((scope) => {
      scope.setExtras(extra);
      scope.setTag('page', 'popup_login');
      scope.setTag('individual_errors', 'true');
      eventID = Sentry.captureEvent({
        message: title || 'Popup->Personal->Login',
      });
    });

    return eventID;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.email.input.value || !validateEmail(this.email.input.value)) {
      alert('Заполните поле Email');
      return false;
    }

    if (!this.password.input.value) {
      alert('Заполните поле Пароль');
      return false;
    }

    this.setState({ loading: true }, () => {
      const userLogin = {
        email: this.email.input.value,
        password: this.password.input.value,
      };

      this.props.apiInstance
        .post('/login', qs.stringify(userLogin), {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then((res) => {
          const { data } = res;

          if (data && data.token) {
            if (window.ga) {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'Personal',
                eventAction: 'Login',
                eventLabel: 'success',
              });
            }

            this.props.onLogin(data);
          } else {
            const sentryID = this.sendError(
              {
                sendData: userLogin,
                response: res,
                responseData: data,
              },
              'Popup Login Token Error',
            );

            alert(
              `Ошибка #10005 | ${sentryID}. Возможно неправильный логин/пароль. Если вы уверены, что всё правильно, напишите нам в чат и сообщите номера ошибок.`,
            );

            console.log(data, 'data');
          }
        })
        .catch((error) => {
          let data = {};

          if (error.response) {
            data = error.response.data;
          }

          if (data.error === '0x0000404') {
            alert('Не правильный логин или пароль');
          } else {
            if (window.jivo_api) {
              window.jivo_api.open();
            }

            const sentryID = this.sendError(
              {
                sendData: userLogin,
                errorResponse: error.response,
                errorCode: data.error || '',
                errorMessage: data.message || '',
              },
              'Popup Login Fetch Error',
            );

            alert(
              `Ошибка #10006 | ${sentryID}. Напишите нам в чат и сообщите номера ошибок.`,
            );
            console.log(error.response, 'error');
          }
        });
    });
  };

  render() {
    return (
      <>
        <Auth>
          <Title>Авторизация</Title>
          <Description>
            Для доступа к личному кабинету введите свой E-mail и пароль
          </Description>

          <Form onSubmit={this.onSubmit}>
            <Input
              label="Email"
              type="email"
              name="email"
              ref={(email) => (this.email = email)}
            />
            <Input
              label="Пароль"
              type="password"
              name="password"
              ref={(password) => (this.password = password)}
            />

            {/* <Checkbox
              isActive={this.state.saveData}
              onClick={() => this.setState({ saveData: !this.state.saveData })}
            >
              Запомнить данные входа
            </Checkbox> */}

            <Buttons>
              <Button type="submit">Войти</Button>

              <ButtonReset
                type="button"
                onClick={(e) => {
                  if (window.ga) {
                    window.ga('send', {
                      hitType: 'event',
                      eventCategory: 'Personal',
                      eventAction: 'Forget Password',
                      eventLabel: 'open',
                    });
                  }

                  this.props.onChangeModal({
                    name: 'forgetPassword',
                    openingPoint: {
                      x: e.clientX,
                      y: e.clientY,
                    },
                  });
                }}
              >
                Забыли пароль?
              </ButtonReset>
            </Buttons>
          </Form>
        </Auth>

        <Register>
          <MiniTitle>Нет аккаунта?</MiniTitle>

          <EmptyButton
            mobilePadding="12px 25px"
            onClick={() => this.props.onChangeTab('register')}
          >
            Зарегистрироваться
          </EmptyButton>
        </Register>
      </>
    );
  }
}

export default PopupLogin;
