import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  :hover {
    opacity: 0.7;
  }
`;

const Box = styled.div`
  width: 20px;
  height: 11px;
  display: inline-block;
  position: relative;
`;

const Inner = styled.div`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.color2 : 'transparent'};
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 20px;
  height: 2px;
  border-radius: 1px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.18s;
  transition-timing-function: ease;

  &::before,
  &::after {
    background-color: ${({ isActive, theme }) => theme.colors.color2};
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 1px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.18s;
    transition-timing-function: ease;

    transition: bottom 0.18s ease-out, top 0.18s ease-out,
      opacity 0s ${({ isActive }) => isActive && '0.18s'} linear;
    opacity: ${({ isActive }) => (isActive ? 0 : 1)};
  }

  &::before {
    top: ${({ isActive }) => (isActive ? 0 : -4)}px;
  }
  &::after {
    bottom: ${({ isActive }) => (isActive ? 0 : -4)}px;
  }
`;

const HamburgerButton = ({ isActive }) => (
  <Wrapper>
    <Box>
      <Inner isActive={isActive} />
    </Box>
  </Wrapper>
);

export default HamburgerButton;
