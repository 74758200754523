import React from 'react';
import * as Sentry from '@sentry/browser';

import { Modal } from 'UI';
import Subscribe from 'features/Subscribe';

const sendError = (extra, title) => {
  let eventID = false;

  Sentry.withScope((scope) => {
    scope.setExtras(extra);
    scope.setTag('page', 'subscribe_modal');
    scope.setTag('individual_errors', 'true');
    eventID = Sentry.captureEvent({
      message: title || 'Modal->Subscribe',
    });
  });

  return eventID;
};

export default ({ isOpen, onClose, openingPoint, onChangeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      openingPoint={openingPoint}
      isCustomUpdateComponent
    >
      <Subscribe sendErrorCb={sendError} />
    </Modal>
  );
};
