export const CART_TIMEOUT_KEY = 'cartLastUpdate';
export const CART_UPDATE_TIMEOUT = 600000;

export const PHOTO_SIZES = {
  xxs: '200x200',
  xs: '300x300',
  s: '400x400',
  m: '500x500',
  l: '600x600',
  xl: '700x700',
  xxl: '800x800',
  full: 'full',
};

export const CATEGORIES_IDS = {
  BOOKS: 59,
  REPRODUCTIONS: 62,
  HOME: 63,
  CLOTHES: 67,
  JEWERLY: 60,
  STATIONERY: 66,
  KIDS: 65,
  SCULPTURE: 265,
  COLLECTIONS: 300,
  GRAPHICS: 154,
};

export const SORT_OPTIONS = [
  {
    id: 1,
    sortby: 'price',
    orderby: 'asc',
    name: 'По цене (от самой низкой)',
  },
  {
    id: 2,
    sortby: 'price',
    orderby: 'desc',
    name: 'По цене (от самой высокой)',
  },
  {
    id: 3,
    sortby: 'alphabet',
    orderby: 'asc',
    name: 'По алфавиту',
  },
  {
    id: 4,
    sortby: 'newproducts',
    orderby: 'asc',
    name: 'По новинкам',
  },
  {
    id: 5,
    sortby: 'popular',
    orderby: 'asc',
    name: 'По популярности',
  },
];

export const SUBSCRIBE_EMAIL_EXIST_ERROR_CODE = '0x0000003';

export const BANNERS_NAMES = {
  BAUHAUS: 'bauhaus',
  YELLOWCASE: 'yellowcase',
  DECORHOME: 'decorhome',
  BLACK_FRIDAY: 'blackfriday',
  PENCIL: 'pencil',
  VASE: 'vase',
  ANGEL: 'angel',
  VANGOGH: 'vangogh',
  XMAS: 'xmas2020',
  PLATE: 'plate',
  POTHOLDER: 'potholder',
  YELLOWBAG: 'yellowbag',
  BARETTE: 'barette',
  CAPSPRING: 'capspring',
  GREATWOMEN: 'greatwomen',
  JEWELRY: 'jewelry',
  UKRASHENIA: 'ukrashenia',
};

export const IMAGES_STORE_URL = 'https://www.hermitageshop.ru/banners';

export const MOBILE_BANNERS_RESOLUTIONS = [
  {
    lcd: '280x430',
    retina: '560x840',
  },
  {
    lcd: '320x480',
    retina: '640x960',
  },
  {
    lcd: '335x498',
    retina: '670x1000',
  },
  {
    lcd: '374x560',
    retina: '748x1120',
  },
  {
    lcd: '728x1090',
    retina: '1456x2180',
  },
];

export const DESKTOP_BANNERS_RESOLUTIONS = {
  l: {
    lcd: '1196x628',
    retina: '2392x1256',
    aspectRatio: 1.9014,
    small: {
      lcd: '785x628',
      retina: '1570x1256',
      aspectRatio: 1.25,
    },
  },
  xl: {
    lcd: '1270x760',
    retina: '2540x1520',
    aspectRatio: 1.671,
    small: {
      lcd: '950x760',
      retina: '1900x1520',
      aspectRatio: 1.25,
    },
  },
  xxl: {
    lcd: '1366x724',
    retina: '2732x1448',
    aspectRatio: 1.886,
    small: {
      lcd: '905x724',
      retina: '1810x1448',
      aspectRatio: 1.25,
    },
  },
  xxxl: {
    lcd: '1750x940',
    retina: '3500x1880',
    aspectRatio: 1.8617,
    small: {
      lcd: '1175x940',
      retina: '2350x1880',
      aspectRatio: 1.25,
    },
  },
};

export const DESKTOP_BANNERS_BREAKPOINTS = {
  l: 1200,
  xl: 1400,
  xxl: 1600,
  xxxl: 1920,
};

export const BANNERS_IMAGES_EXTENSIONS = {
  png: 'png',
  jpg: 'jpg',
};

export const TOKEN_EXPIRATION_TIME = 1728000000;

// 1608768000000

// 1635465600000

export const NEW_YEAR_NOTIFICATION_TIME_START = 1640304000000;

// 1609545599000

// 1636329599000

export const NEW_YEAR_NOTIFICATION_TIME_END = 1641081599000;
export const DEFAULT_THEME_TOGGLE_HOUR = 6;
export const DARK_THEME_TOGGLE_HOUR = 21;
