import React from 'react';
import 'normalize.css';
import './index.css';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import getHours from 'date-fns/getHours';

import { LoadingRoute } from 'UI';
import { checkAuth, isNightTime, getThemeToggleTimeout } from 'Utils';
import { theme, GlobalStyles, setDarkTheme, setDefaultTheme } from 'UI/theme';
import { currentThemeSelector, isDefaultThemeSelector } from 'common/selectors';
import Route from './Layout';

import store from './store';

// Pages
const loadDelay = 300;

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './Home'),
  modules: ['Home'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Products = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Products" */ './features/Products'),
  modules: ['Products'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const ProductPage = Loadable({
  loader: () => import(/* webpackChunkName: "ProductPage" */ './ProductPage'),
  modules: ['ProductPage'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Vouchers = Loadable({
  loader: () => import(/* webpackChunkName: "Vouchers" */ './Vouchers'),
  modules: ['Vouchers'],
  loading: LoadingRoute,
  delay: loadDelay,
});

// const VR = Loadable({
//   loader: () => import('./VR'),
//   loading: LoadingRoute,
//   delay: loadDelay,
// });

const About = Loadable({
  loader: () => import(/* webpackChunkName: "About" */ './About'),
  modules: ['About'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const FAQ = Loadable({
  loader: () => import(/* webpackChunkName: "FAQ" */ './FAQ'),
  modules: ['FAQ'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Shipping = Loadable({
  loader: () => import(/* webpackChunkName: "Shipping" */ './Shipping'),
  modules: ['Shipping'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Exchange = Loadable({
  loader: () => import(/* webpackChunkName: "Exchange" */ './Exchange'),
  modules: ['Exchange'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Warranty = Loadable({
  loader: () => import(/* webpackChunkName: "Warranty" */ './Warranty'),
  modules: ['Warranty'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const NotFound = Loadable({
  loader: () => import(/* webpackChunkName: "NotFound" */ './NotFound'),
  modules: ['NotFound'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Checkout = Loadable({
  loader: () => import(/* webpackChunkName: "Checkout" */ './Checkout'),
  modules: ['Checkout'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Search = Loadable({
  loader: () => import(/* webpackChunkName: "Search" */ './Search'),
  modules: ['Search'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const SubscribeConfirm = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SubscribeConfirm" */ './SubscribeConfirm'),
  modules: ['SubscribeConfirm'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const CheckoutSuccess = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Checkout/Success" */ './Checkout/Success'),
  modules: ['Checkout/Success'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const RegisterConfirm = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RegisterConfirm" */ './RegisterConfirm'),
  modules: ['RegisterConfirm'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Organization = Loadable({
  loader: () => import(/* webpackChunkName: "Organization" */ './Organization'),
  modules: ['Organization'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const OrderChange = Loadable({
  loader: () => import(/* webpackChunkName: "OrderChange" */ './OrderChange'),
  modules: ['OrderChange'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Tours = Loadable({
  loader: () => import(/* webpackChunkName: "Tours" */ './Tours'),
  modules: ['Tours'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Tour = Loadable({
  loader: () => import(/* webpackChunkName: "Tour" */ './Tours/Tour'),
  modules: ['Tour'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const SuccessTourPurchase = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Tour" */ './Tours/Tour/PaymentSuccess'),
  modules: ['PaymentSuccess'],
  loading: LoadingRoute,
  delay: loadDelay,
});

// let history = [];

// if (typeof window !== 'undefined') {
//   history = createMemoryHistory();
// } else {
//   history = createBrowserHistory();
// }

// export default () => (
//   <Provider store={store}>
//     <Helmet
//       titleTemplate="%s ● Эрмитажный магазин"
//       defaultTitle="Эрмитажный магазин"
//     />

//     <Route path="/" exact component={Home} />
//   </Provider>
// );
const Routes = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(currentThemeSelector);
  const isDefaultTheme = useSelector(isDefaultThemeSelector);
  React.useEffect(() => {
    checkAuth();
  }, []);

  React.useEffect(() => {
    const timeOut = getThemeToggleTimeout();

    const t = setTimeout(() => {
      const isNight = isNightTime();
      if (isDefaultTheme) {
        return isNight && dispatch(setDarkTheme());
      } else {
        return !isNight && dispatch(setDefaultTheme());
      }
    }, timeOut);

    return () => clearTimeout(t);
  }, [isDefaultTheme]);

  return (
    <BrowserRouter>
      <ThemeProvider
        theme={{ ...theme[currentTheme], isDefault: isDefaultTheme }}
      >
        <>
          <GlobalStyles />

          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/category/:category" exact component={Products} />
            <Route
              path="/category/:category/:subcategory"
              exact
              component={Products}
            />
            <Route path="/product/:id" exact component={ProductPage} />
            <Route path="/product/code/:model" exact component={ProductPage} />
            <Route path="/tickets" exact component={Vouchers} />
            <Route path="/vouchers/success" component={Vouchers} />
            <Route
              path="/subscribe/confirm/:hash"
              exact
              component={SubscribeConfirm}
            />
            {/* <Route path="/vr" exact component={VR} /> */}
            <Route path="/about" exact component={About} />
            <Route path="/orderchange" exact component={OrderChange} />
            <Route path="/faq" exact component={FAQ} />
            <Route path="/shipping" exact component={Shipping} />
            <Route path="/exchange" exact component={Exchange} />
            <Route path="/warranty" exact component={Warranty} />
            <Route path="/checkout" exact component={Checkout} />
            <Route path="/organization" exact component={Organization} />
            <Route path="/checkout/success" exact component={CheckoutSuccess} />
            <Route
              path="/register/confirm/:hash"
              exact
              component={RegisterConfirm}
            />
            <Route path="/search/:search" exact component={Search} />
            <Route path="/tours" exact defaultThemeOnly component={Tours} />
            <Route path="/tours/:id" exact defaultThemeOnly component={Tour} />
            <Route
              path="/tours/checkout/success"
              exact
              defaultThemeOnly
              component={SuccessTourPurchase}
            />
            <Route component={NotFound} />
          </Switch>
        </>
      </ThemeProvider>
    </BrowserRouter>
  );
};

class App extends React.Component {
  startHelmet = () => (
    <Helmet
      // titleTemplate="%s • Эрмитажный магазин"
      // defaultTitle="Эрмитажный магазин"
    >
      <meta property="og:url" content="https://www.hermitageshop.ru" />

      <meta
        property="og:image"
        content="https://www.hermitageshop.ru/favicons/favicon_192.png"
      />
    </Helmet>
  );

  render() {
    return (
      <Provider store={store}>
        {typeof window !== 'undefined' && this.startHelmet()}
        <Routes />
      </Provider>
    );
  }
}

export default App;
