import React, { useCallback, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import {
  Title4 as TitleOriginal,
  Input,
  MiniCheckbox,
  Button,
  SuccessMessage,
} from 'UI';
import { validateEmail } from 'Utils';
import { subscribe } from 'api/modals';
import { SUBSCRIBE_EMAIL_EXIST_ERROR_CODE } from '../../constants';

const Wrapper = styled.div``;

const Title = styled(TitleOriginal)`
  text-align: center;
`;

const InputsWrapper = styled.div`
  max-width: 235px;
  margin: 0 auto;
  margin-top: 50px;
`;

const HiddenArea = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

const CheckboxWrapper = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 50px;
`;

const LinkModal = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
`;

const initialState = {
  email: '',
  name: '',
  isFullOpened: false,
  agreementApplied: false,
  success: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'EMAIL_CHANGED':
      return { ...state, email: payload };
    case 'NAME_CHANGED':
      return { ...state, name: payload };
    case 'SHOW_MODE_CHANGED':
      return { ...state, isFullOpened: payload };
    case 'AGREEMENT_APPLY_CHANGED':
      return { ...state, agreementApplied: payload };
    case 'SUCCESS_STATUS_CHANGED':
      return { ...state, success: payload };
    default:
      return initialState;
  }
};

export default ({ sendErrorCb }) => {
  const dispatch = useDispatch();
  const [state, stateDispatch] = useReducer(reducer, initialState);

  const { email, name, isFullOpened, agreementApplied, success } = state;

  const openPersonalDataAgreement = useCallback(
    (e) =>
      dispatch({
        type: 'CHANGE_MODAL',
        payload: {
          name: 'personalData',
          openingPoint: {
            x: e.clientX,
            y: e.clientY,
          },
        },
      }),
    [],
  );

  const showAll = useCallback(
    () => stateDispatch({ type: 'SHOW_MODE_CHANGED', payload: true }),
    [],
  );

  const onChangeEmail = useCallback(
    ({ value }) => stateDispatch({ type: 'EMAIL_CHANGED', payload: value }),
    [],
  );

  const onChangeName = useCallback(
    ({ value }) => stateDispatch({ type: 'NAME_CHANGED', payload: value }),
    [],
  );

  const onTogglePersonalDataAgreement = useCallback(
    () =>
      stateDispatch({
        type: 'AGREEMENT_APPLY_CHANGED',
        payload: !agreementApplied,
      }),
    [agreementApplied],
  );

  const onSubmit = useCallback(async () => {
    if (!agreementApplied) {
      return alert(
        'Пожалуйста, прочтите условия Обработки персональных данных.',
      );
    } else if (!email || !validateEmail(email)) {
      return alert('Заполните поле Email');
    } else if (!name) {
      return alert('Заполните поле Имя');
    }

    const sendData = {
      email,
      name,
    };

    try {
      const { data } = await subscribe(sendData);

      if (data.result && Boolean(data.result)) {
        stateDispatch({ type: 'SUCCESS_STATUS_CHANGED', payload: true });
      }
    } catch (error) {
      const { data } = error.response;
      const emailIsExistError =
        data && data.error === SUBSCRIBE_EMAIL_EXIST_ERROR_CODE;

      if (emailIsExistError) {
        return alert('Подписка уже успешно оформлена!');
      }

      if (window.jivo_api) {
        window.jivo_api.open();
      }

      const sentryID = sendErrorCb(
        {
          sendData,
          errorResponse: error.response,
          errorCode: error.response || '',
          errorMessage: data ? data.message : '',
        },
        'Product Page Subscribe Fetch Error',
      );
      console.log(error);

      return alert(
        `Ошибка #10009 | ${sentryID}. Напишите нам в чат и сообщите номера ошибок.`,
      );
    }
  }, [email, name, agreementApplied]);

  const handleLeaveFocus = useCallback(() => {
    if (isFullOpened) {
      if (!email.length && !name.length && !agreementApplied && !success) {
        stateDispatch({ type: 'SHOW_MODE_CHANGED', payload: false });
      }
    }
  }, [email, name, isFullOpened, agreementApplied, success]);

  return (
    <>
      {state.success && (
        <SuccessMessage msg="На указанный Email отправлено подтверждение" />
      )}
      {!state.success && (
        <Wrapper>
          <Title>Узнайте первыми о скидках и специальных предложениях</Title>

          <InputsWrapper>
            <Input
              label="Email"
              alignLabel="center"
              type="email"
              onChange={onChangeEmail}
              onFocus={showAll}
              onBlur={handleLeaveFocus}
            />

            <HiddenArea isOpen={isFullOpened}>
              <Input label="Имя" alignLabel="center" onChange={onChangeName} />
            </HiddenArea>
          </InputsWrapper>

          <CheckboxWrapper isOpen={isFullOpened}>
            <MiniCheckbox
              onClick={onTogglePersonalDataAgreement}
              isActive={agreementApplied}
            >
              Я прочел (прочла) условия{' '}
              <LinkModal
                onClick={(e) => {
                  e.stopPropagation();
                  openPersonalDataAgreement(e);
                }}
              >
                Обработки персональных данных.
              </LinkModal>
            </MiniCheckbox>
          </CheckboxWrapper>

          <ButtonWrapper isOpen={isFullOpened}>
            <Button onClick={onSubmit}>Подписаться</Button>
          </ButtonWrapper>
        </Wrapper>
      )}
    </>
  );
};
