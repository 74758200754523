export default function hasOptionsDiscount(options) {
  let hasDiscount = false;

  options.map((option) =>
    option.values.map((optionValue) => {
      if (
        optionValue.discount &&
        optionValue.instock &&
        parseInt(optionValue.discount, 10) > 0 &&
        parseInt(optionValue.instockquantity, 10) > 0
      ) {
        hasDiscount = true;
      }

      return optionValue;
    }),
  );

  return hasDiscount;
}
