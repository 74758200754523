import React from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';

import SearchIcon from '../../Header/SearchIcon';

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 300;
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  outline: none;
  background-color: ${({ theme }) => theme.colors.color1};
  color: ${({ theme }) => theme.colors.color2};

  ::placeholder {
    color: ${({ theme }) => theme.colors.color2};
  }

  @media screen and (min-width: 1200px) {
    font-size: 31px;
    padding: 0 40px;
  }
`;

const WrapperIcon = styled.button`
  display: block;
  background: transparent;
  border: none;
  position: absolute;
  right: 20px;
  top: 50%;
  top: calc(50% - 10px);
  cursor: pointer;

  @media screen and (min-width: 1200px) {
    right: 40px;
  }
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
`;

class Main extends React.Component {
  state = {
    text: '',
  };

  input = null;

  componentDidMount() {
    this.props.setRef(this.input);
  }

  shouldComponentUpdate() {
    return false;
  }

  onWheelOnOpened = (e) => {
    e.preventDefault();
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (window.ga) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Popup Search',
        eventAction: 'dosearch',
        eventLabel: this.state.text,
      });
    }

    this.props.history.push(`/search/${this.state.text}`);
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Input
          ref={(input) => {
            this.input = input;
          }}
          placeholder="Что вы хотите найти"
          name="search"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          onChange={(e) => this.setState({ text: e.target.value })}
          // onBlur={() => this.props.onClose()}
        />
        <WrapperIcon type="submit">
          <SearchIcon width="21px" isHover insideHeader />
        </WrapperIcon>
      </Form>
    );
  }
}

export default withRouter(Main);
