import React from 'react';
import styled from 'styled-components/macro';

import { Button, Modal } from '../UI';

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 31px;
  font-weight: 700;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
`;

const Info = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
`;

export default class ExemptionInfo extends React.Component {
  componentDidMount() {}

  render() {
    const { isOpen, onClose, openingPoint } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose} openingPoint={openingPoint}>
        <>
          <Title>Информация по детским билетам</Title>
          <Description>Ознакомьтесь</Description>

          <Info>
            <p>
              Приобретая ваучеры на организацию посещения музея, вы можете
              добавить к каждому заказу 
              <strong>до двух бесплатных билетов на детей до 16 лет</strong>.
            </p>

            <p>
              Обратите внимание, что при оформлении заказа, в котором есть
              бесплатные детские билеты, информация о них будет указана только{' '}
              <strong>в основном ваучере (в первом ваучере из заказа)</strong>,
              который вы получите по электронной почте. Отдельные бесплатные
              детские билеты не формируется электронным способом, и вам{' '}
              <strong>
                будет необходимо получить их непосредственно при посещении музея
              </strong>
              . Для этого необходимо обратиться в Интернет-кассу Главного
              музейного комплекса или в кассу посещаемого Вами другого
              экспозиционного объекта (точную информацию о нахождении кассы
              можно получить у администратора входной зоны) и назвать номер
              заказа. При себе необходимо иметь любой документ, подтверждающий
              возраст ребенка.
            </p>
          </Info>

          <ButtonWrap>
            <Button padding="17px 70px" onClick={this.props.onClose}>
              Всё понятно
            </Button>
          </ButtonWrap>
        </>
      </Modal>
    );
  }
}
