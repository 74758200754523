import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { isDefaultThemeSelector } from 'common/selectors';
import { Alink } from '../styled';

import logo from './logoImg.svg';
import darkLogo from './dark_logoImg.svg';

//import logo from './hermitage-newyear-logo.svg';
//import darkLogo from './hermitage-newyear-logo-dark.svg';

const LinkLogo = styled.a`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.18s;
  position: relative;
  margin-right: 0;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

const LogoImg = styled.img`
  flex-basis: auto;
  max-width: 100px;
  width: 100px;
  transition: all 0.2s linear;

  @media screen and (min-width: 1200px) {
    max-width: 199px;
    width: 199px;
  }
`;

class Logo extends React.PureComponent {
  logoDOM = null;

  componentDidMount() {
    this.props.onRender(this.logoDOM);
  }

  render() {
    const { isDefaultTheme } = this.props;
    return (
      <LinkLogo href="/">
        <LogoImg
          src={isDefaultTheme ? logo : darkLogo}
          ref={(e) => {
            this.logoDOM = e;
          }}
        />
      </LinkLogo>
    );
  }
}

const mapStateToProps = (state) => ({
  isDefaultTheme: isDefaultThemeSelector(state),
});

export default connect(mapStateToProps)(Logo);
