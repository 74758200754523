import React from 'react';
import styled from 'styled-components/macro';

import { Button, Modal } from '../UI';

import mapExcursion from 'UI/map_excursion.svg';
import darkMapExcursion from 'UI/dark_map_excursion.svg';

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 31px;
  font-weight: 700;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
`;

const Info = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
`;

const Map = styled.img.attrs(({ theme }) => ({
  src: theme.isDefault ? mapExcursion : darkMapExcursion,
  alt: 'map to excursions"',
}))``;

export default class ExcursionsInfo extends React.Component {
  componentDidMount() {}

  render() {
    const { isOpen, onClose, openingPoint } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose} openingPoint={openingPoint}>
        <>
          <Title>Информация об экскурсиях</Title>
          <Description>Ознакомьтесь</Description>

          <Info>
            <p>
              При заказе входных ваучеров на посещение музея, вы можете также
              заказать экскурсии в Золотую и Бриллиантовую кладовые.
            </p>

            <p>
              Заказ экскурсии онлайн позволит вам забронировать место в
              экскурсионной группе заранее и избежать очереди в кассу за
              экскурсионной путевкой.
            </p>

            <p>
              Экскурсионный ваучер дает право на посещение групповой экскурсии в
              указанные в ваучере день и время.
            </p>

            <p>
              Обратите внимание: заказ экскурсии онлайн возможен только при
              заказе входных ваучеров на посещение музея. Количество участников
              экскурсии в одном заказе не может превышать количество входных
              ваучеров в этом заказе.
            </p>

            <p>
              При заказе двух экскурсий вы получите экскурсионный ваучер на
              каждого посетителя на каждую из экскурсий.
            </p>

            <p>
              Каждый экскурсионный ваучер имеет индивидуальный штрих-код,
              который необходимо отсканировать на входе в Кладовую. Полученные
              экскурсионные ваучеры можно распечатать или сохранить на мобильных
              устройствах.
            </p>

            <p>
              Бесплатный детский экскурсионный билет при заказе экскурсий онлайн
              не предусмотрен.
            </p>

            <Map />
          </Info>

          <ButtonWrap>
            <Button padding="17px 70px" onClick={onClose}>
              Всё понятно
            </Button>
          </ButtonWrap>
        </>
      </Modal>
    );
  }
}
