import { handleActions } from 'redux-actions';

import {
  categoryChanged,
  subcategoryChanged,
  categoryNotFounded,
  categoriesInited,
} from 'common/actions';
import {
  cardsFetchLoading,
  cardsFetchMoreLoading,
  cardsFetchSuccess,
  initialCardsFetchSuccess,
  cardsFetchFailed,
  serverDataEnded,
  sortChanged,
} from './actions';

const initialState = {
  cards: [],
  subCategories: [],
  isLoading: false,
  fetchMoreLoading: false,
  error: false,
  categoryId: 0,
  subCategoryId: 0,
  offset: 0,
  totalAmount: 0,
  sortID: 5,
  sortBy: 'popular',
  orderBy: 'asc',
  name: '',
  description: '',
  notFound: false,
  limit: window.innerWidth < 1200 ? 12 : 9,
  serverDataIsEmpty: false,
};

export default handleActions(
  {
    [categoryChanged]: (state, { payload }) => ({
      ...state,
      ...payload,
      categoryId: payload.id,
      subCategoryId: 0,
      cards: [],
    }),
    [subcategoryChanged]: (state, { payload }) => ({
      ...state,
      subCategoryId: payload,
      cards: [],
    }),
    [categoriesInited]: (state, { payload }) => {
      const { parentID, id } = payload;

      const categoryId = parentID === 0 ? id : parentID;
      const subCategoryId = parentID === 0 ? 0 : id;

      const stateIsEqual =
        categoryId === state.categoryId &&
        subCategoryId === state.subCategoryId;

      return stateIsEqual ? state : { ...state, categoryId, subCategoryId };
    },
    [cardsFetchLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    [cardsFetchMoreLoading]: (state, { payload }) => ({
      ...state,
      fetchMoreLoading: payload,
    }),
    [cardsFetchFailed]: (state, { payload }) => ({ ...state, error: payload }),
    [cardsFetchSuccess]: (state, { payload }) => {
      const { data, ...rest } = payload;
      const { offset, orderby, sortby, totalamount } = rest;

      return {
        ...state,
        offset,
        sortBy: sortby,
        orderBy: orderby,
        totalAmount: parseInt(totalamount, 10),
        cards: [...state.cards, ...data],
      };
    },
    [initialCardsFetchSuccess]: (state, { payload }) => {
      const { data, ...rest } = payload;
      const { offset, orderby, sortby, totalamount } = rest;

      return {
        ...state,
        offset,
        sortBy: sortby,
        orderBy: orderby,
        totalAmount: parseInt(totalamount, 10),
        serverDataIsEmpty: false,
        error: false,
        cards: data,
      };
    },
    [serverDataEnded]: (state, { payload }) => ({
      ...state,
      serverDataIsEmpty: payload,
    }),
    [sortChanged]: (state, { payload }) => ({ ...state, sortID: payload }),
    [categoryNotFounded]: (state, { payload }) => ({
      ...state,
      notFound: payload,
    }),
  },
  initialState,
);
