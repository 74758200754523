import React from 'react';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';

import { themeSelector } from 'common/selectors';
import { isPopupOpen, addEventWheel } from 'Utils';

import HeaderInner from './HeaderInner';

class Header extends React.Component {
  currentHeight =
    typeof window !== 'undefined' ? (window.innerWidth < 1200 ? 50 : 70) : 50;

  innerDOM = null;

  logoDOM = null;

  innerBackgroundDOM = null;

  logoWidth = '199px';

  innerBackground = 'rgba(250,250,250, 1)';

  innerBorder = '1px solid rgba(243, 243, 243, 0)';

  innerActiveBackground = this.props.theme.colors.color1;

  innerActiveBorder = `1px solid ${this.props.theme.colors.color4}`;

  constructor(props) {
    super(props);

    this.trottledScroll = throttle(() => this.onScroll(true), 0);
  }

  componentDidMount() {
    const { scrollingDisabled } = this.props;

    if (!scrollingDisabled && window.innerWidth >= 1200) {
      window.addEventListener('scroll', this.trottledScroll, false);
    }
  }

  componentDidUpdate(prevProps) {
    const { theme } = this.props;

    if (theme.colors.color1 !== this.innerActiveBackground) {
      this.innerActiveBackground = theme.colors.color1;
    }

    if (theme.colors.color4 !== prevProps.theme.colors.color4) {
      this.innerActiveBorder = `1px solid ${theme.colors.color4}`;
    }

    if (!isPopupOpen(this.props.popups) && this.currentHeight === 70) {
      this.manageHeaderStyles(false);
    }

    if (window.innerWidth >= 1200) {
      const { scrollingDisabled, isPopupOpened } = this.props;

      const {
        scrollingDisabled: prevScrollingDisabled,
        isPopupOpened: prevIsPopupOpen,
      } = prevProps;

      if (isPopupOpen !== prevIsPopupOpen) {
        this.changeSideScroll(isPopupOpened);
      }

      if (prevScrollingDisabled !== scrollingDisabled) {
        if (scrollingDisabled) {
          this.onScroll(false);
          window.removeEventListener('scroll', this.trottledScroll, false);
        } else {
          window.addEventListener('scroll', this.trottledScroll, false);
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.trottledScroll, false);
  }

  disableSideScroll = (e) => {
    e.preventDefault();
  };

  changeSideScroll = (isEnable) => {
    if (this.innerDOM) {
      if (isEnable) {
        addEventWheel(true, this.innerDOM, this.disableSideScroll);
      } else {
        addEventWheel(false, this.innerDOM, this.disableSideScroll);
      }
    }
  };

  onSetRefs = (innerDOM, logoDOM, backgroundDOM) => {
    this.innerDOM = innerDOM;
    this.logoDOM = logoDOM;
    this.innerBackgroundDOM = backgroundDOM;

    this.props.setHeaderRef(innerDOM);
  };

  onScroll = (isActive) => {
    const top = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop,
    );

    const newHeight = isActive ? Math.max(70 - top, 50) : 70;

    if (this.currentHeight !== newHeight || !isActive) {
      this.currentHeight = newHeight;

      if (
        this.innerDOM !== null &&
        this.logoDOM !== null &&
        this.innerBackgroundDOM !== null
      ) {
        this.innerDOM.style.height = `${this.currentHeight}px`;

        if (this.currentHeight === 70) {
          if (isPopupOpen(this.props.popups)) {
            this.manageHeaderStyles(
              this.innerActiveBackground,
              this.innerActiveBorder,
              this.logoWidth,
            );
          } else {
            this.manageHeaderStyles(false);
          }
        } else {
          const currentPercentHeight =
            (100 * (70 - this.currentHeight)) / (70 - 50);
          const logoWidth = `${199 -
            ((70 - this.currentHeight) / 37) * currentPercentHeight}px`;

          this.manageHeaderStyles(
            this.innerActiveBackground,
            this.innerActiveBorder,
            logoWidth,
          );
        }
      }
    }

    return true;
  };

  manageHeaderStyles = (innerBackground, innerBorder, logoWidth) => {
    if (innerBackground) {
      this.innerBackgroundDOM.style.backgroundColor = innerBackground;
      this.innerDOM.style.borderBottom = innerBorder;
      this.logoDOM.style.maxWidth = logoWidth;
    } else {
      this.innerDOM.removeAttribute('style');
      this.innerBackgroundDOM.removeAttribute('style');
      this.logoDOM.removeAttribute('style');
    }
  };

  onChangePopup = (name) => {
    if (name && !this.props.popups[name].isOpen) {
      this.props.onChangePopup({
        [name]: {
          isOpen: true,
        },
      });
    } else {
      this.props.onChangePopup(false);
    }
  };

  render() {
    const {
      popups,
      popups: { currentShift },
    } = this.props;
    const isPopupOpened = isPopupOpen(popups);

    let openedPopup = null;

    if (isPopupOpened) {
      openedPopup = Object.keys(popups).filter(
        (popupName) => popups[popupName].isOpen,
      )[0];
    }

    return (
      <HeaderInner
        currentHeight={this.currentHeight}
        isPopupOpen={isPopupOpened}
        openedPopup={openedPopup}
        setHeaderRefs={this.onSetRefs}
        currentShift={currentShift}
        onChangePopup={this.onChangePopup}
        popups={popups}
        pathScheme={this.props.pathScheme}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  theme: themeSelector(state),
});

export default connect(mapStateToProps)(Header);
