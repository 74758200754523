export default {
  en: {
    allProducts: 'Все товары',
    personalArea: 'Личный кабинет',
    visitMuseum: 'Посещение музея',
  },
  ru: {
    allProducts: 'Все товары',
    personalArea: 'Личный кабинет',
    visitMuseum: 'Посещение музея',
  },
};
