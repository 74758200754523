import styled from 'styled-components/macro';

import Checkbox from './Checkbox';

const MiniCheckbox = styled(Checkbox)`
  font-size: 8px;
  font-weight: 600;
  padding-left: 20px;
  color: ${({ theme }) => theme.colors.color2};

  &:before {
    width: 10px;
    height: 10px;
    border-radius: 4px;
    border-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : 'inherit'};
    top: -2px;
  }

  &:after {
    width: 6px;
    height: 6px;
    border-radius: 2px;
    top: 1px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 12px;
    font-weight: 500;

    &:before {
      top: 0;
    }

    &:after {
      top: 3px;
    }
  }
`;

export default MiniCheckbox;
