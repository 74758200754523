import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import isEmpty from 'lodash/isEmpty';

import Preloader from 'UI/Preloader';

import { updateCartProducts } from './actions';
import { productsSelector, cartLoadingSelector } from './selectors';
import { checkUpdateRequirement } from './lib/cartUpdateTimeoutService';
import Empty from './Empty';
import List from './List';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Main = ({
  isOpen,
  onClose,
  currentPath,
  headerHeight,
  setScrollableContainer,
}) => {
  const dispatch = useDispatch();
  const products = useSelector(productsSelector);
  const isLoading = useSelector(cartLoadingSelector);

  const onChangeQuantity = useCallback(
    (payload) => dispatch({ type: 'CHANGE_QUANTITY_PRODUCT', payload }),
    [],
  );

  const onDelete = useCallback(
    (id) => dispatch({ type: 'DELETE_FROM_CART', payload: id }),
    [],
  );

  useEffect(() => {
    if (isOpen && !isEmpty(products)) {
      const isUpdateRequirement = checkUpdateRequirement();

      if (isUpdateRequirement) {
        dispatch(updateCartProducts());
      }
    }
  }, [isOpen]);

  return (
    <Wrapper>
      {isLoading && <Preloader />}
      {!isLoading && (
        <Content>
          {isEmpty(products) ? (
            <Empty onClose={onClose} />
          ) : (
            <List
              cart={products}
              onClose={onClose}
              onChangeQuantity={onChangeQuantity}
              onDelete={onDelete}
              allowEdit
              currentPath={currentPath}
              setScrollableContainer={setScrollableContainer}
              headerHeight={headerHeight}
            />
          )}
        </Content>
      )}
    </Wrapper>
  );
};

export default Main;
