import { createActions } from 'redux-actions';
import { batch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { fetchProducts } from 'api/products';
import getPhotoSizes from './lib/getPhotoSize';
import validateCards from '../../Utils/validateProductCards';

export const {
  cardsFetchLoading,
  cardsFetchMoreLoading,
  cardsFetchSuccess,
  initialCardsFetchSuccess,
  cardsFetchFailed,
  serverDataEnded,
  sortChanged,
} = createActions(
  'CARDS_FETCH_LOADING',
  'CARDS_FETCH_MORE_LOADING',
  'CARDS_FETCH_SUCCESS',
  'INITIAL_CARDS_FETCH_SUCCESS',
  'CARDS_FETCH_FAILED',
  'SERVER_DATA_ENDED',
  'CATEGORIES_INITED',
  'SORT_CHANGED',
);

const sendError = (extra, title) => {
  Sentry.withScope((scope) => {
    scope.setExtras(extra);
    scope.setTag('page', 'catalog');
    scope.setTag('individual_errors', 'true');

    Sentry.captureEvent({
      message: title || 'Catalog actions',
    });
  });
};

const startRecursiveCardsFetch = async (
  categoryId,
  limit,
  offSet,
  sortBy,
  orderBy,
  photoSize,
  acc,
  successCb,
  errorCb,
) => {
  const res = await fetchProducts(
    categoryId,
    limit,
    offSet,
    sortBy,
    orderBy,
    photoSize,
  );

  if (res.status !== 200) {
    return errorCb();
  }

  const { data, ...rest } = res.data;

  const validatedCards = validateCards(data);
  const newAcc = [...acc, ...validatedCards];
  const newLimit = limit - validatedCards.length;
  const newOffset = offSet + data.length;
  const total = parseInt(rest.totalamount);

  if (validatedCards.length === limit || newOffset >= total) {
    return successCb({ ...rest, data: newAcc, offset: newOffset });
  }

  const updatedLimit =
    newOffset + newLimit > total ? total - newOffset : newLimit;

  return startRecursiveCardsFetch(
    categoryId,
    updatedLimit,
    newOffset,
    sortBy,
    orderBy,
    photoSize,
    newAcc,
    successCb,
    errorCb,
  );
};

export const initialFetchCards = () => async (dispatch, getState) => {
  dispatch(cardsFetchLoading(true));

  const {
    categoryId,
    subCategoryId,
    sortBy,
    orderBy,
    limit,
  } = getState().products;

  const photoSize = getPhotoSizes();
  const targetCategory = subCategoryId !== 0 ? subCategoryId : categoryId;

  try {
    await startRecursiveCardsFetch(
      targetCategory,
      limit,
      0,
      sortBy,
      orderBy,
      photoSize,
      [],
      (res) => dispatch(initialCardsFetchSuccess(res)),
      () => dispatch(cardsFetchFailed(true)),
    );
  } catch (e) {
    dispatch(cardsFetchFailed(true));
    sendError(e, 'Initial fetch cards action');
  }

  dispatch(cardsFetchLoading(false));
};

export const getCards = () => async (dispatch, getState) => {
  const {
    categoryId,
    limit,
    subCategoryId,
    offset,
    sortBy,
    orderBy,
    totalAmount,
  } = getState().products;

  const photoSize = getPhotoSizes();

  let newLimit = limit;

  if (totalAmount !== 0 && offset === totalAmount) {
    return dispatch(serverDataEnded(true));
  }

  if (totalAmount !== 0) {
    const serverDataIsEmpty = offset + limit > totalAmount;

    if (serverDataIsEmpty) {
      newLimit = totalAmount - offset;

      dispatch(serverDataEnded(true));
    }
  }
  const targetCategory = subCategoryId !== 0 ? subCategoryId : categoryId;

  dispatch(cardsFetchMoreLoading(true));
  try {
    await startRecursiveCardsFetch(
      targetCategory,
      newLimit,
      offset,
      sortBy,
      orderBy,
      photoSize,
      [],
      (res) => dispatch(cardsFetchSuccess(res)),
      () => dispatch(cardsFetchFailed(true)),
    );
  } catch (e) {
    dispatch(cardsFetchFailed(true));

    sendError(e, 'On scroll cards fetch action');
  }

  dispatch(cardsFetchMoreLoading(false));
};

export const changeSort = (sortID, sortBy, orderBy) => async (
  dispatch,
  getState,
) => {
  dispatch(cardsFetchLoading(true));

  const { categoryId, subCategoryId } = getState().products;

  const photoSize = getPhotoSizes();
  const targetCategory = subCategoryId !== 0 ? subCategoryId : categoryId;

  try {
    const limit = window.innerWidth < 1200 ? 12 : 9;

    await startRecursiveCardsFetch(
      targetCategory,
      limit,
      0,
      sortBy,
      orderBy,
      photoSize,
      [],
      (res) =>
        batch(() => {
          dispatch(initialCardsFetchSuccess(res));
          dispatch(sortChanged(sortID));
        }),
      () => dispatch(cardsFetchFailed(true)),
    );
  } catch (e) {
    dispatch(cardsFetchFailed(true));

    sendError(e, 'Change sort option action');
  }

  dispatch(cardsFetchLoading(false));
};
