const theme = {
  default: {
    colors: {
      color1: '#ffffff',
      color2: '#000000',
      color3: '#fafafa',
      color4: '#f3f3f3',
      color5: '#f5f5f5',
      color6: '#F0F0F0',
      color7: '#ededed',
      color8: '#cdcdcd',
      color9: '#a5a5a5',
      color10: '#8b8b8b',
      color11: '#656565',
      color12: '#141414',
      rgbaColor1: 'rgba(243, 243, 243, 1)',
      rgbaColor2Transparent: 'rgba(255, 255, 255, 0)',
      rgbaColor1Transparent: 'rgba(243, 243, 243, 0)',
      rgbaColor11Transparent: 'rgba(101, 101, 101, 0.2)',
      primary: '#e71d85',
      rgbaPrimaryTransparent: 'rgba(231, 29, 133, 0)',
    },
  },
  dark: {
    colors: {
      color1: '#2B2B2D',
      color2: '#E9EAEA',
      color3: '#121212',
      color4: '#1A1A1B',
      color5: '#212121',
      color6: '#414143',
      color7: '#1F2023',
      color8: '#504F4F',
      color9: '#706F6F',
      color10: '#A6A6A6',
      color11: '#A8A8A8',
      color12: '#E9EAEA',
      rgbaColor1: 'rgba(26, 26, 27, 1)',
      rgbaColor1Transparent: 'rgba(26, 26, 27, 0)',
      rgbaColor2Transparent: 'rgba(43, 43, 45, 0)',
      rgbaColor11Transparent: 'rgba(168, 168, 168, 0.2)',
      primary: '#CF086E',
      rgbaPrimaryTransparent: 'rgba(207, 8, 110, 0)',
    },
  },
};

export default theme;
