import React from 'react';
import styled from 'styled-components/macro';

import './circleLoader.css';

const Circle = styled.div`
  width: '80px';
  height: '80px';
  border-radius: 100%;
  border: 2px solid #cdcdcd;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircleWait = () => (
  <Circle>
    <div className="circleLoader" />
  </Circle>
);

export default CircleWait;
