import React from 'react';
import styled, { css } from 'styled-components/macro';

import { addEventWheel } from '../Utils';

import { popupCross } from './icons';

const Cross = styled.button`
  border: none;
  background: transparent;
  position: fixed;
  z-index: 5;
  opacity: 1;
  width: 15px;
  height: 15px;
  background-image: url(${popupCross});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  visibility: hidden;
  transition: left 0.5s ease 0s, right 0.5s ease 0s, top 0.5s ease 0s,
    bottom 0.5s ease 0s;

  @media screen and (min-width: 1200px) {
    z-index: 5;
  }
`;

const ContainerScroll = styled.div`
  height: 100%;
  width: 100%;
`;

const Container = styled.div.attrs(({ location, isOpen, minWidth }) => ({
  style: {
    transform: getOpenedTransform(isOpen, minWidth, location),
    [location]: 0,
  },
}))`
  position: fixed;
  top: 0;
  padding-top: ${(props) => props.headerHeight}px;
  z-index: 10;
  width: ${(props) => `${props.minWidth}px`};
  height: 100%;
  max-width: 87vw;
  transition: visibility 0.25s ease, transform 0.25s ease;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  /* ${(props) => props.location}: ${(props) =>
  props.isOpen ? 0 : `-${props.minWidth + 15}px`}; */
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.color1};

  @media screen and (max-width: 1200px) {
    overflow: auto;
  }

  @media screen and (min-width: 1200px) {
    transition: visibility 0.35s ease, transform 0.35s ease;
  }
`;

const Wrapper = styled.div.attrs(({ location, minWidth }) => ({
  style: {
    [location]: `-${minWidth}px`,
  },
}))`
  width: ${(props) => `${props.minWidth}px`};
  height: 100%;
  position: fixed;
  z-index: 27;
  top: 0;

  ${Cross} {
    visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};

    ${(props) =>
      props.location &&
      css`
        ${props.location}: 90vw;
        top: calc(50vh - 8px);
        opacity: 0.35;

        @media screen and (min-width: 1200px) {
          ${props.location}: ${props.isOpen
            ? `${props.minWidth + 15}px`
            : `-${props.minWidth}px`};
          top: ${props.headerHeight + 15}px;
          opacity: 1;
        }
      `}
  }
`;

function getOpenedTransform(isOpen, minWidth, location) {
  if (location === 'left') {
    if (isOpen) {
      return 'translate(0, 0)';
    }

    return 'translate(-100%, 0)';
  }

  if (location === 'right') {
    if (isOpen) {
      return 'translate(0, 0)';
    }

    return 'translate(100%, 0)';
  }

  if (location === 'top') {
    if (isOpen) {
      return 'translate(0, 0)';
    }

    return 'translate(100%, 0)';
  }

  if (location === 'bottom') {
    if (isOpen) {
      return 'translate(0, 0)';
    }

    return 'translate(-100%, 0)';
  }

  return 'translate(0, 0)';
}

export default class Popup extends React.PureComponent {
  scrollableContainer = null;

  onWheel = null;

  componentWillUnmount() {
    addEventWheel(
      false,
      this.scrollableContainer,
      this.onWheel || this.onWheelScrollableContainer,
    );
  }

  setScrollableContainer = (el, onWheel) => {
    if (el) {
      this.scrollableContainer = el;
      this.onWheel = onWheel;

      addEventWheel(
        true,
        this.scrollableContainer,
        this.onWheel || this.onWheelScrollableContainer,
      );
    }
  };

  onWheelScrollableContainer = (el) => {
    if (this.scrollableContainer.scrollTop === 0 && el.deltaY < 0) {
      el.preventDefault();
    } else if (
      this.scrollableContainer.offsetHeight +
        this.scrollableContainer.scrollTop ===
        this.scrollableContainer.scrollHeight &&
      el.deltaY > 0
    ) {
      el.preventDefault();
    }
  };

  render() {
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        setScrollableContainer: this.setScrollableContainer,
      }),
    );

    return (
      <Wrapper
        minWidth={this.props.width}
        isOpen={this.props.isOpen}
        location={this.props.location}
        headerHeight={this.props.headerHeight}
      >
        <Container
          isOpen={this.props.isOpen}
          headerHeight={this.props.headerHeight}
          minWidth={this.props.width}
          location={this.props.location}
        >
          <ContainerScroll>{childrenWithProps}</ContainerScroll>
        </Container>

        {/* <Cross onClick={() => this.props.onClose()} /> */}
      </Wrapper>
    );
  }
}
