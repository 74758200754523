import React from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.colors.color3};
  min-height: 100vh;
  padding-top: 50px;
  /* filter: ${(props) => (props.isOpenPopup ? 'blur(1px)' : 'none')}; */
  /* user-select: ${(props) => (props.isOpenPopup ? 'none' : 'initial')}; */
  transition: all 0.35s ease;

  
  /* &:before {
    content: '';
    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(221, 221, 221, 0.8);
    opacity: ${(props) => (props.isOpenPopup ? '1' : '0')};
    transition: all 0.3s;
    visibility: ${(props) => (props.isOpenPopup ? 'visible' : 'hidden')};
  } */

  @media screen and (max-width: 1199px) {
    /* hack for mobile performance */
    transform: none !important;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 70px;

    ${(props) =>
      !props.isHome &&
      css`
        margin-bottom: ${props.footerHeight}px;
      `}

    &:before {
      z-index: 25;
    }
  }
`;

export default class LayoutWrapper extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.currentPath !== this.props.currentPath ||
      this.props.isOpenPopup !== nextProps.isOpenPopup
    ) {
      // alert();
      return true;
    }

    return false;
  }

  render() {
    return (
      <Wrapper
        footerHeight={this.props.getFooterHeight()}
        isHome={this.props.currentPath === '/'}
        ref={(el) => this.props.setRef(el)}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}
