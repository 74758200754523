import header from './Header/i18n';

const i18n = {
  en: {
    header: header.en,
  },
  ru: {
    header: header.ru,
  },
};

export default i18n;
