import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';

import parseRelativePathSlug from 'Utils/parseRelativePathSlug';
import { theme } from 'UI/theme';

const cartSelector = (state) => state.cart;
const apiSelector = (state) => state.api;
const currentProductsSelector = (state) => state.products;
export const currentThemeSelector = (state) => state.theme.current;

export const productsSelector = createSelector(
  cartSelector,
  (cart) => cart.products,
);

export const categoriesSelector = createSelector(
  apiSelector,
  (state) => state.categories,
);

export const mainCategoriesSelector = createSelector(
  apiSelector,
  (state) =>
    orderBy(state.categories, (category) => parseInt(category.priority, 10), [
      'asc',
    ]).filter(
      (category) =>
        parseInt(category.parentID, 10) === 0 &&
        !category.hidden &&
        category.status &&
        !!parseRelativePathSlug(category.slug),
    ),
);

export const subCategoriesSelector = createSelector(
  currentProductsSelector,
  apiSelector,
  (productsState, apiState) => {
    const { categories } = apiState;
    const { categoryId } = productsState;

    return categories.filter((el) => el.parentID === categoryId);
  },
);

export const currentCategoryIdSelector = createSelector(
  currentProductsSelector,
  (products) => products.categoryId,
);

export const subCategoriesDataSelector = createSelector(
  currentProductsSelector,
  (products) =>
    pick(products, ['subCategories', 'subCategoryId', 'categoryId']),
);

export const productsDataSelector = createSelector(
  currentProductsSelector,
  (state) =>
    pick(state, [
      'isLoading',
      'categoryId',
      'subCategoryId',
      'cards',
      'serverDataIsEmpty',
      'error',
      'notFound',
    ]),
);

export const themeSelector = createSelector(
  currentThemeSelector,
  (key) => theme[key],
);

export const isDefaultThemeSelector = createSelector(
  currentThemeSelector,
  (key) => key === 'default',
);
