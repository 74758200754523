import {
  IMAGES_STORE_URL,
  MOBILE_BANNERS_RESOLUTIONS,
  DESKTOP_BANNERS_RESOLUTIONS,
  DESKTOP_BANNERS_BREAKPOINTS,
} from '../constants';

const { l, xl, xxl, xxxl } = DESKTOP_BANNERS_BREAKPOINTS;
const {
  l: desktopL,
  xl: desktopXL,
  xxl: desktopXXL,
  xxxl: desktopXXXL,
} = DESKTOP_BANNERS_RESOLUTIONS;

const getUrl = (name, bannerExtension, resolution) =>
  `${IMAGES_STORE_URL}/${name}/${resolution}.${bannerExtension}`;

const getUrlsEnum = (name, bannerExtension, { lcd, retina, aspectRatio }) => ({
  lcd: getUrl(name, bannerExtension, lcd),
  retina: getUrl(name, bannerExtension, retina),
  aspectRatio,
});

export const getMobileImagesSet = (bannerName, bannerExtension) => {
  return MOBILE_BANNERS_RESOLUTIONS.map(({ lcd, retina }) => ({
    lcd: getUrl(bannerName, bannerExtension, lcd),
    retina: getUrl(bannerName, bannerExtension, retina),
  }));
};

const getActualBanner = (aspectRatio, banner) => {
  return aspectRatio > 1.7 ? banner : banner.small;
};

export const getBannerUrl = (
  bannerName,
  bannerExtension,
  width,
  aspectRatio,
) => {
  if (width >= xxxl) {
    const banner = getActualBanner(aspectRatio, desktopXXXL);
    return getUrlsEnum(bannerName, bannerExtension, banner);
  } else if (width >= xxl) {
    const banner = getActualBanner(aspectRatio, desktopXXL);
    return getUrlsEnum(bannerName, bannerExtension, banner);
  } else if (width >= xl) {
    const banner = getActualBanner(aspectRatio, desktopXL);
    return getUrlsEnum(bannerName, bannerExtension, banner);
  } else {
    const banner = getActualBanner(aspectRatio, desktopL);
    return getUrlsEnum(bannerName, bannerExtension, banner);
  }
};
