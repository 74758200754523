import root from './config';

export const getActualProductsInfo = (ids) => root.get(`product/${ids}`);

export const fetchCategories = () => root.get('/categories/0');

export const fetchProducts = (
  categoryId,
  limit,
  offset,
  sortBy,
  orderBy,
  photosSize,
) =>
  root.get(
    `/cards/categoryid/${categoryId}/limit/${limit}/offset/${offset}/sortby/${sortBy}/orderby/${orderBy}/sizephotos/${photosSize}`,
  );
