import React from 'react';
import styled from 'styled-components/macro';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { Vk, Facebook, Instagram } from 'UI';

import {
  BottomWrapper as Wrapper,
  Documents,
  SocialLinks,
  MobileSocialLinks,
  // LinkBottom as Link,
  PointBottom as Point,
  Copyright,
  Settings,
  RegionWrapper,
  SettingsTitle,
  Region,
  LanguageWrapper,
  Language,
  Flag,
  RegionName,
  LanguageTag,
  LanguageName,
  FakeLinkLinkBottom as FakeLink,
} from './styled';

import Hermitage from './Hermitage';

import russiaFlag from './flags/russia@3x.png';

const HermitageMobile = styled.div`
  display: flex;
  max-width: 160px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 80px;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const MobileLine = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.color7};
  margin: 0 30px;
  margin-top: 30px;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1200px) {
    justify-content: space-around;
  }
`;

const SocialTitle = styled.span`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 10.5px;
  padding-top: 2px;
  color: ${({ theme }) => theme.colors.color11};
`;

export default class Bottom extends React.Component {
  state = {
    isDesktop: window.innerWidth > 1200,
  };

  componentDidMount() {
    window.addEventListener('resize', () =>
      this.setState(() => ({ isDesktop: window.innerWidth > 1200 })),
    );
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.homePage !== this.props.homePage) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    window.removeEventListener('resize');
  }

  render() {
    const { isDesktop } = this.state;
    return (
      <Wrapper homePage={!!this.props.homePage}>
        <Grid fluid={!!this.props.homePage}>
          <Row around="xl">
            {!isDesktop && (
              <Col xs={12}>
                <Row center="xs">
                  <MobileSocialLinks>
                    <SocialTitle>Подписывайтесь на нас:</SocialTitle>
                    <LinksWrapper>
                      <Vk variant="grey" />
                      <Facebook variant="grey" />
                      <Instagram variant="grey" />
                    </LinksWrapper>
                  </MobileSocialLinks>
                </Row>
              </Col>
            )}
            <Col xs={12} xl={7}>
              <Documents homePage={!!this.props.homePage}>
                <Point>
                  <FakeLink
                    onClick={(e) =>
                      this.props.onChangeModal({
                        name: 'personalData',
                        openingPoint: {
                          x: e.clientX,
                          y: e.clientY,
                        },
                      })
                    }
                  >
                    Обработка персональных данных
                  </FakeLink>
                </Point>

                <Point>
                  <FakeLink
                    onClick={(e) =>
                      this.props.onChangeModal({
                        name: 'cookies',
                        openingPoint: {
                          x: e.clientX,
                          y: e.clientY,
                        },
                      })
                    }
                  >
                    Использование Cookies
                  </FakeLink>
                </Point>

                <Point>
                  <FakeLink
                    onClick={(e) =>
                      this.props.onChangeModal({
                        name: 'oferta',
                        openingPoint: {
                          x: e.clientX,
                          y: e.clientY,
                        },
                      })
                    }
                  >
                    Публичная оферта
                  </FakeLink>
                </Point>

                {/* <Point>
                  <FakeLink
                    onClick={() => this.props.onOpenModal('isOpenPersonalData')}
                  >
                    Обработка персональных данных
                  </FakeLink>
                </Point> */}

                {/* <Point>
                  <Link to="/corpclients" href="/corpclients">
                    Корпоративным клиентам
                  </Link>
                </Point> */}
              </Documents>

              {/* <Copyright>
                © Государственный Эрмитаж | © Museum Online, Inc. | 2003 —{' '}
                {new Date().getFullYear()} г. | Все права защищены
              </Copyright> */}
              
              <Copyright>
                © Государственный Эрмитаж | © ООО "Артеком". | 2003 —{' '}
                {new Date().getFullYear()} г. | Все права защищены
              </Copyright>

              <MobileLine />

              <HermitageMobile>
                <Hermitage mobile />
              </HermitageMobile>
            </Col>

            {isDesktop && (
              <Col xs={1} style={{ paddingLeft: 0 }}>
                <SocialLinks>
                  <SocialTitle>Подписывайтесь на нас:</SocialTitle>
                  <LinksWrapper>
                    <Vk variant="grey" margin="0 15px 0 0" />
                    <Facebook variant="grey" margin="0 15px 0 0" />
                    <Instagram variant="grey" />
                  </LinksWrapper>
                </SocialLinks>
              </Col>
            )}

            <Col xs={3} className="hidden-lg hidden-md hidden-sm hidden-xs">
              <Settings>
                <RegionWrapper>
                  <SettingsTitle>Вы находитесь здесь:</SettingsTitle>

                  <Region>
                    <Flag src={russiaFlag} />
                    <RegionName>Россия</RegionName>
                  </Region>
                </RegionWrapper>

                <LanguageWrapper>
                  <SettingsTitle>Язык:</SettingsTitle>

                  <Language>
                    <LanguageTag>RU</LanguageTag>
                    <LanguageName>Русский</LanguageName>
                  </Language>
                </LanguageWrapper>
              </Settings>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
    );
  }
}
