import styled from 'styled-components/macro';

const Checkbox = styled.div`
  font-size: 10px;
  font-weight: 500;
  position: relative;
  padding-left: 28px;
  color: ${({ theme }) => theme.colors.color2};
  outline: none;
  cursor: pointer;

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
  }

  &:before {
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.color8};
    width: 16px;
    height: 16px;
    top: 0px;
  }

  &:after {
    transition: all 0.18s;
    width: 12px;
    height: 12px;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : theme.colors.rgbaPrimaryTransparent};
    border-radius: 3px;
    left: 3px;
    top: 3px;
    transform: ${(props) => (props.isActive ? 'scale(1)' : 'scale(0)')};
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

export default Checkbox;
