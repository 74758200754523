import React from 'react';
import { useTranslation } from 'react-i18next';

import { Links } from '../styled';
import LinkWithIcon from '../LinkWithIcon';

const LinksLeft = (props) => {
  const { isMenuOpen, isPopupOpen, isPersonalOpen, onChangePopup } = props;
  const { t, i18n } = useTranslation();

  return (
    <Links left>
      <LinkWithIcon
        to="/category/bestsellers"
        isActive={isMenuOpen}
        isPopupOpen={isPopupOpen}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('menu');
        }}
        name={t('header:allProducts')}
        iconName="Items"
        iconWidth="20px"
        iconMargin="0 7px 0 0"
      />

      <LinkWithIcon
        to="/auth"
        isActive={isPersonalOpen}
        isPopupOpen={isPopupOpen}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('personal');
        }}
        name={t('header:personalArea')}
        iconName="Ingerprint"
        iconWidth="20px"
        iconMargin="0 7px 0 0"
      />
    </Links>
  );
};

export default LinksLeft;
