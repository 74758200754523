import { ON_LOGIN, ON_LOGOUT } from './userActions';

let initialUser = {};

if (typeof window !== 'undefined') {
  initialUser = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : {};
}

export default function popupReducer(state = initialUser, action) {
  if (action.type === ON_LOGIN) {
    const user = action.payload;
    /* retail rocket */
    if (user && !!parseInt(user.issubscribe, 10)) {
      window.rrApiOnReady.push(() => {
        window.rrApi.setEmail(user.email, {
          age: user.age || '',
          gender: user.gender || '',
          birthday: user.birthday || '',
          name: user.name || '',
        });
      });
    }

    const loginDate = Date.now();

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('login_date', loginDate);
    return user;
  }

  if (action.type === ON_LOGOUT) {
    localStorage.removeItem('user');
    localStorage.removeItem('login_date');
    return {};
  }

  return state;
}
