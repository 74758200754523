import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;

const Label = styled.h4`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
`;

const UserName = styled.h3`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 5px;
`;

const Logout = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.color11};
  cursor: pointer;
  outline: none;
  transition: all 0.18s;

  &:hover {
    opacity: 0.7;
  }
`;

const MainButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.color4};
`;

export default (props) => (
  <Wrapper>
    <Label>Вы авторизованы как</Label>
    <UserName>{props.user.name}</UserName>

    <MainButtons>
      <Logout onClick={() => props.onLogout(props.user.token)}>Выйти</Logout>
    </MainButtons>
  </Wrapper>
);
