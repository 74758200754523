import {
  shape, bool, number, objectOf,
} from 'prop-types';

export const modalsTypes = objectOf(
  shape({
    isOpen: bool,
    openingPoint: shape({
      x: number,
      y: number,
    }),
  }),
);
