import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

import CHANGE_POPUP from './actions';
import { changePopupVisibility } from 'common/actions';

const initialPopups = {
  menu: {
    isOpen: false,
    size: {
      width: 405,
      height: 0,
    },
    shift: {
      x: 0,
      y: 405,
    },
  },

  search: {
    isOpen: false,
    size: {
      width: 0,
      height: 60,
    },
    shift: {
      x: 0,
      y: 60,
    },
  },

  cart: {
    isOpen: false,
    size: {
      width: 520,
      height: 0,
    },
    shift: {
      x: 0,
      y: -520,
    },
  },

  visitmuseum: {
    isOpen: false,
    size: {
      width: 405,
      height: 0,
    },
    shift: {
      x: 0,
      y: -405,
    },
  },

  personal: {
    isOpen: false,
    size: {
      width: 405,
      height: 0,
    },
    shift: {
      x: 0,
      y: 405,
    },
  },

  mobile: {
    isOpen: false,
    size: {
      width: 405,
      height: 0,
    },
    shift: {
      x: -405,
      y: 0,
    },
  },

  currentShift: {
    x: 0,
    y: 0,
  },
};

function getShift(name) {
  let shift = {};

  if (name === 'menu') {
    shift = {
      x: initialPopups.menu.size.width,
      y: 0,
    };
  }

  if (name === 'search') {
    shift = {
      x: 0,
      y: initialPopups.search.size.height,
    };
  }

  if (name === 'cart') {
    shift = {
      x: -initialPopups.cart.size.width,
      y: 0,
    };
  }

  if (name === 'visitmuseum') {
    shift = {
      x: -initialPopups.visitmuseum.size.width,
      y: 0,
    };
  }

  if (name === 'personal') {
    shift = {
      x: initialPopups.personal.size.width,
      y: 0,
    };
  }

  if (name === 'mobile') {
    shift = {
      x: initialPopups.mobile.size.width,
      y: 0,
    };
  }

  return shift;
}

export default handleActions(
  {
    [CHANGE_POPUP]: (state, { payload }) => {
      if (!payload) return initialPopups;

      const shift = getShift(Object.keys(payload)[0]);

      return {
        ...merge({}, initialPopups, payload),
        currentShift: shift,
      };
    },
    [changePopupVisibility]: (state, { payload }) => {
      const { name, popupStatus } = payload;
      const shift = getShift(name);

      const targetPopupState = initialPopups[name];
      const newPopupState = { ...targetPopupState, isOpen: popupStatus };
      return {
        ...initialPopups,
        [name]: newPopupState,
        currentShift: shift,
      };
    },
  },
  initialPopups,
);
