export default function parseRelativePathSlug(str) {
  const regexp = /(?:\w+:)?\/\/[^/]+([^?#]+)/;
  const path = str.match(regexp)[1];

  if (path === '/index.php') {
    return '';
  }

  return path.slice(1, -1);
}

// /index.php | https://dev.hermitageshop.ru/index.php?route=product/category&amp;path=168_176
