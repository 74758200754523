// only desktop component
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Link as RLink } from 'react-router-dom';

import { parseRelativePathSlug } from 'Utils';
import {
  mainCategoriesSelector,
  isDefaultThemeSelector,
} from 'common/selectors';
import { changeCategory } from 'common/actions';

import HorizontalScroll from '../HorizontalScroll';

const Wrapper = styled.div`
  /* border-bottom: 1px solid #ededed; */
  position: relative;
  overflow: hidden;
  display: none;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.color7};
  }

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

const Category = styled(RLink)`
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary : theme.colors.color11};
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
  flex: 0 0 auto;
  position: relative;
  transition: all 0.18s;

  &:first-child {
    padding-left: 40px;
  }

  &:last-child {
    padding-right: 40px;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: all 0.3s ease;
  }

  &:after {
    left: 50%;
    width: ${(props) => (props.isActive ? '50%' : 0)};
  }

  &:before {
    right: 50%;
    width: ${(props) => (props.isActive ? '50%' : 0)};
  }

  &:hover {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : theme.colors.color2};
  }
`;

const MainCategories = ({ pathname }) => {
  const dispatch = useDispatch();
  const mainCategories = useSelector(mainCategoriesSelector);
  const isDefaultTheme = useSelector(isDefaultThemeSelector);

  const onClickCategory = useCallback(
    (category) => () => {
      if (window.ga) {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'DetailMenu: Main Categories',
          eventAction: 'click',
          eventLabel: `${category.name} | ${parseRelativePathSlug(
            category.slug,
          )}`,
        });
      }

      dispatch(changeCategory(category));
    },
    [],
  );

  return (
    <Wrapper>
      <HorizontalScroll isDefaultTheme={isDefaultTheme}>
        {mainCategories.map((category) => (
          <Category
            to={`/category/${parseRelativePathSlug(category.slug)}`}
            href={`/category/${parseRelativePathSlug(category.slug)}`}
            key={`detailMenuMain_${category.id}`}
            isActive={
              pathname === `/category/${parseRelativePathSlug(category.slug)}`
            }
            onClick={onClickCategory(category)}
            draggable={false}
          >
            {category.name}
          </Category>
        ))}
      </HorizontalScroll>
    </Wrapper>
  );
};

export default MainCategories;
