export default (isOpen, name, nonInteraction = true) => {
  if (window.ga) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: `Popup: ${name}`,
      eventAction: isOpen ? 'open' : 'close',
      nonInteraction,
    });
  }
};
