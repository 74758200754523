import { combineReducers } from 'redux';

import cartReducer from './Popups/Cart/reducer';
import popupsReducer from './Popups/reducer';
import apiReducer from './Layout/apiReducer';
import userReducer from './Layout/userReducer';
import modalsReducer from './Modals/reducer';
import productsReducer from 'features/Products/reducers';

import { themeReducer } from 'UI/theme';

const region = process.env.REACT_APP_REGION || 'ru';

export default combineReducers({
  cart: cartReducer,
  popups: popupsReducer,
  api: apiReducer,
  user: userReducer,
  modals: modalsReducer,
  products: productsReducer,
  region: region,
  theme: themeReducer,
});
