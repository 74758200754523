export const getIdsQuery = (ids) => ids.join(',');

export const updateProduct = (product, actualData) => {
  const { quantity, id } = product;

  const actualProduct = Array.isArray(actualData)
    ? actualData.find((el) => el.id === id)
    : actualData;

  if (!actualProduct) return product;

  const { price, discount, instockquantity } = actualProduct;

  if (instockquantity === 0) return null;

  const newQuantity = quantity > instockquantity ? instockquantity : quantity;

  return {
    quantity: newQuantity,
    price,
    discount,
    maxquantity: instockquantity,
    ...product,
  };
};
