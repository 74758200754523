import React from 'react';
import styled from 'styled-components/macro';
import find from 'lodash/find';

import { decodeHtmlSpecialChars } from '../../Utils';

import Product from './Product';

const ListProducts = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

function actualEcommerce(product) {
  if (
    product &&
    product.id &&
    product.name &&
    (product.price || product.startPrice)
  ) {
    const mainCategory = find(product.categories, (category) =>
      Boolean(category.ismain),
    );

    let price = parseFloat(product.price || product.startPrice);
    let name = decodeHtmlSpecialChars(product.name);

    if (product.discount && product.discount > 0) {
      name = `СКИДКА: ${name}`;
      price = parseFloat(product.discount);
    }

    if (window.ga) {
      window.ga('ec:addProduct', {
        id: product.id, // Product ID (string).
        name, // Product name (string).
        category: mainCategory || '', // Product category (string).
        price,
        quantity: product.quantity,
      });

      window.ga('ec:setAction', 'remove');

      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Product Card',
        eventAction: 'remove from cart',
      });
    }

    window.dataLayer.push({
      ecommerce: {
        remove: {
          products: [
            {
              id: product.id,
              name,
              price,
              category: mainCategory ? mainCategory.name : '',
              quantity: product.quantity,
            },
          ],
        },
      },
    });
  }
}

export default (props) => (
  <ListProducts>
    {props.cart.map((product) => (
      <Product
        {...product}
        onChangeQuantity={(quantity) => {
          props.onChangeQuantity({ cartID: product.cartID, quantity });
        }}
        onDelete={() => {
          actualEcommerce(product);
          props.onDelete(product.cartID);
        }}
        key={`cartProduct_${product.id}`}
        allowEdit={props.allowEdit}
      />
    ))}
  </ListProducts>
);
