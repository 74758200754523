import React from 'react';
import styled, { css } from 'styled-components/macro';

const Svg = styled.svg`
  margin: ${(props) => (props.margin ? props.margin : '')};
  width: 20px;

  @media screen and (min-width: 1200px) {
    width: ${(props) => props.width};
  }
`;

const PathAnimate = styled.path`
  stroke-dasharray: ${(props) => props.dashArray};
  stroke-dashoffset: ${(props) => props.dashOffset};
  stroke: ${({ theme }) => theme.colors.primary};
  transition: stroke-dashoffset 0.8s;

  ${(props) =>
    props.isHover &&
    css`
      stroke-dashoffset: ${(propsHovered) => propsHovered.dashOffsetOnHover};
    `};
`;

const CircleAnimate = styled.circle`
  stroke-dasharray: ${(props) => props.dashArray};
  stroke-dashoffset: ${(props) => props.dashOffset};
  stroke: ${({ theme }) => theme.colors.primary};
  transition: stroke-dashoffset 0.1s;

  ${(props) =>
    props.isHover &&
    css`
      stroke-dashoffset: ${(propsHovered) => propsHovered.dashOffsetOnHover};
      fill: ${({ theme }) => theme.colors.primary};
    `};
`;

const FillCartPath = styled.path`
  stroke-dasharray: ${(props) => props.dashArray};
  stroke-dashoffset: ${(props) =>
    props.isExtraVisible ? 0 : props.dashOffset};
  transition: stroke-dashoffset 0.5s;
  fill: transparent;

  ${(props) =>
    props.isHover &&
    css`
      stroke-dashoffset: ${(propsHovered) => propsHovered.dashOffsetOnHover};
    `};
`;

const FillCartPathAnimate = styled.path`
  stroke-dasharray: ${(props) => props.dashArray};
  stroke-dashoffset: ${(props) => props.dashOffset};
  transition: stroke-dashoffset 0.5s;
  fill: transparent;
  stroke: ${({ theme }) => theme.colors.primary};

  ${(props) =>
    props.isHover &&
    props.isExtraVisible &&
    css`
      stroke-dashoffset: ${(propsHovered) => propsHovered.dashOffsetOnHover};
    `};
`;

export default class CartIcon extends React.PureComponent {
  render() {
    const { strokeColor } = this.props;
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27 26"
        width={this.props.width}
        margin={this.props.margin}
      >
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
          <path
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 5.625h17.006A2.99 2.99 0 0 1 25 8.616v2.57c0 1.652-1.315 3.267-2.93 3.605l-11.64 2.438a2.975 2.975 0 0 1-3.532-2.328L5 5.625z"
          />

          <PathAnimate
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 5.625h17.006A2.99 2.99 0 0 1 25 8.616v2.57c0 1.652-1.315 3.267-2.93 3.605l-11.64 2.438a2.975 2.975 0 0 1-3.532-2.328L5 5.625z"
            dashArray="55"
            dashOffset="55"
            dashOffsetOnHover="0"
            isHover={this.props.isHover}
          />

          <path
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5.128 6.184L3.75 0H0"
          />

          <PathAnimate
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5.128 6.184L3.75 0H0"
            dashArray="11"
            dashOffset="11"
            dashOffsetOnHover="0"
            isHover={this.props.isHover}
          />

          <circle cx="8" cy="22.625" r="2" fill={strokeColor} />

          <CircleAnimate
            cx="8"
            cy="22.625"
            r="2"
            fill={strokeColor}
            dashArray="14"
            dashOffset="14"
            dashOffsetOnHover="0"
            isHover={this.props.isHover}
          />

          <circle cx="19.5" cy="22.625" r="2" fill={strokeColor} />

          <CircleAnimate
            cx="19.5"
            cy="22.625"
            r="2"
            fill={strokeColor}
            dashArray="14"
            dashOffset="14"
            dashOffsetOnHover="0"
            isHover={this.props.isHover}
          />
        </g>

        <FillCartPath
          d="M8.6,11.7c8.4,2.5,8.7-2.1,16.4,0"
          strokeLinecap="round"
          strokeLineJoin="round"
          strokeWidth={2}
          stroke={strokeColor}
          dashArray="17"
          dashOffset="17"
          dashOffsetOnHover="0"
          isExtraVisible={this.props.isExtraVisible}
        />

        <FillCartPathAnimate
          d="M8.6,11.7c8.4,2.5,8.7-2.1,16.4,0"
          strokeLinecap="round"
          strokeLineJoin="round"
          strokeWidth={2}
          dashArray="17"
          dashOffset="17"
          dashOffsetOnHover="0"
          isExtraVisible={this.props.isExtraVisible}
          isHover={this.props.isHover}
        />
      </Svg>
    );
  }
}
