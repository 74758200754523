export default function getMainCategory(categories) {
  if (categories && categories.length > 0) {
    const filtered = categories.filter(category => category.ismain === true);

    if (filtered.length === 0) {
      return categories[0].name;
    }

    return filtered[0].name;
  }

  return '';
}
