import React from 'react';
import styled from 'styled-components/macro';

import './preloaderStyles.css';

const Wrapper = styled.div`
  text-align: center;
  margin-top: 40vh;
`;

const Title = styled.h1`
  margin: 0;
`;

export default class Loading extends React.Component {
  renderLoading = () => {
    if (this.props.error) {
      return (
        <div>
          Error!
          {' '}
          <button onClick={this.props.retry}>Retry</button>
        </div>
      );
    }

    if (this.props.timedOut) {
      return (
        <div>
          Taking a long time...
          {' '}
          <button onClick={this.props.retry}>Retry</button>
        </div>
      );
    }

    if (this.props.pastDelay) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Title>Загрузка</Title>

          <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    return <Wrapper>{this.renderLoading()}</Wrapper>;
  }
}
