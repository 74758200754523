import styled from 'styled-components/macro';

const Title4 = styled.h4`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;

  @media screen and (min-width: 1200px) {
    font-size: 19px;
  }
`;

export default Title4;
