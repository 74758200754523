import setHours from 'date-fns/setHours';
import getHours from 'date-fns/getHours';
import addDays from 'date-fns/addDays';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import startOfHour from 'date-fns/startOfHour';

import {
  DEFAULT_THEME_TOGGLE_HOUR,
  DARK_THEME_TOGGLE_HOUR,
} from '../constants';
import isNightTime from './isNightTime';

const getThemeToggleTimeout = () => {
  const isNight = isNightTime();
  const now = Date.now();
  const nowHours = getHours(now);

  if (!isNight) {
    return differenceInMilliseconds(
      startOfHour(setHours(now, DARK_THEME_TOGGLE_HOUR)),
      now,
    );
  }

  return nowHours > DARK_THEME_TOGGLE_HOUR
    ? differenceInMilliseconds(
        startOfHour(setHours(addDays(now, 1), DARK_THEME_TOGGLE_HOUR)),
        now,
      )
    : differenceInMilliseconds(
        startOfHour(setHours(now, DEFAULT_THEME_TOGGLE_HOUR)),
        now,
      );
};

export default getThemeToggleTimeout;
