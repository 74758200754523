import styled from 'styled-components/macro';

const Title2 = styled.h2`
  font-size: 21px;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @media screen and (min-width: 1200px) {
    font-size: 31px;
  }
`;

export default Title2;
