import React from 'react';
import styled from 'styled-components/macro';

import {
  HermitageWrapper,
  HermitageLogo,
  HermitageDescription,
} from './styled';

import hermitageSVG from './hermitageLogo.svg';

const Link = styled.a`
  text-decoration: none;
  color: inherit;
`;

export default ({ mobile }) => {
  return (
    <Link
      href="https://hermitagemuseum.org/"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        if (window.ga) {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'Footer Hermitage Logo',
            eventAction: 'open',
          });
        }
      }}
    >
      <HermitageWrapper mobile={mobile}>
        <HermitageLogo src={hermitageSVG} alt="hermitage" />

        <HermitageDescription>
          Каждая покупка поддерживает Государственный Эрмитаж
        </HermitageDescription>
      </HermitageWrapper>
    </Link>
  );
};
