import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { isDefaultThemeSelector } from 'common/selectors';

import { circleSuccess, darkCircleSuccess } from './icons';
import MainTitle from './MainTitle';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SuccessTitle = styled(MainTitle)`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const SuccessDescription = styled.p`
  font-size: 10px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;

const Icon = styled.img`
  max-width: 60px;
  margin-top: 25px;

  @media screen and (min-width: 1200px) {
    max-width: auto;
    margin-top: 0;
  }
`;

const SuccessMessage = ({ msg }) => {
  const isDefaultTheme = useSelector(isDefaultThemeSelector);

  return (
    <Wrapper>
      <Icon src={isDefaultTheme ? circleSuccess : darkCircleSuccess} />
      <SuccessTitle>Успешно!</SuccessTitle>
      <SuccessDescription>{msg}</SuccessDescription>
    </Wrapper>
  );
};

export default memo(SuccessMessage);
