import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PersonalData from './PersonalData';
import Cookies from './Cookies';
import ExcursionsInfo from './ExcursionsInfo';
import ForgetPassword from './ForgetPassword';
import Oferta from './Oferta';
import Subscribe from './SubscribeModal';
import ExemptionInfo from './ExemptionInfo';
import VouchersInfo from './VouchersInfo';
import ToursAgreement from './ToursAgreement';
import PurchaseInstruction from './PurchaseInstruction';

class Modals extends React.Component {
  componentDidMount() {}

  onClose = (name) => {
    this.props.onChangeModal({
      name,
    });
  };

  render() {
    const {
      modals: {
        personalData,
        cookies,
        excursionsInfo,
        forgetPassword,
        oferta,
        subscribe,
        exemptionInfo,
        vouchersInfo,
        toursAgreement,
        purchaseInstruction,
      },
    } = this.props;

    return (
      <>
        <PersonalData
          isOpen={personalData.isOpen}
          openingPoint={personalData.openingPoint}
          onClose={() => this.onClose('personalData')}
        />

        <Cookies
          isOpen={cookies.isOpen}
          openingPoint={cookies.openingPoint}
          onClose={() => this.onClose('cookies')}
        />

        <ExcursionsInfo
          isOpen={excursionsInfo.isOpen}
          openingPoint={excursionsInfo.openingPoint}
          onClose={() => this.onClose('excursionsInfo')}
        />

        <ForgetPassword
          isOpen={forgetPassword.isOpen}
          openingPoint={forgetPassword.openingPoint}
          onClose={() => this.onClose('forgetPassword')}
          apiInstance={this.props.apiInstance}
        />

        <Oferta
          isOpen={oferta.isOpen}
          openingPoint={oferta.openingPoint}
          onClose={() => this.onClose('oferta')}
        />

        <Subscribe
          isOpen={subscribe.isOpen}
          openingPoint={subscribe.openingPoint}
          onClose={() => this.onClose('subscribe')}
          onChangeModal={this.props.onChangeModal}
        />

        <ExemptionInfo
          isOpen={exemptionInfo.isOpen}
          openingPoint={exemptionInfo.openingPoint}
          onClose={() => this.onClose('exemptionInfo')}
        />

        <VouchersInfo
          isOpen={vouchersInfo.isOpen}
          openingPoint={vouchersInfo.openingPoint}
          onClose={() => this.onClose('vouchersInfo')}
        />
        <ToursAgreement
          isOpen={toursAgreement.isOpen}
          openingPoint={toursAgreement.openingPoint}
          onClose={() => this.onClose('toursAgreement')}
        />
        <PurchaseInstruction
          isOpen={purchaseInstruction.isOpen}
          openingPoint={purchaseInstruction.openingPoint}
          onClose={() => this.onClose('purchaseInstruction')}
        />
      </>
    );
  }
}

export default withRouter(
  connect(
    (store) => ({ modals: store.modals }),
    (dispatch) => ({
      onChangeModal: (modal) => {
        dispatch({ type: 'CHANGE_MODAL', payload: modal });
      },
    }),
  )(Modals),
);
