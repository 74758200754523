import React, { memo } from 'react';
import styled from 'styled-components';

const Picture = styled.picture`
  position: relative;

  img {
    display: block;
    border-radius: 25px;
    width: 100%;
    border-radius: 40px;
  }
`;

const Source = styled.source.attrs(({ urls, mediaSize }) => ({
  srcSet: `${urls.lcd}, ${urls.retina} 2x`,
  media: `(max-width: ${mediaSize}px)`,
}))``;

const MobileAdaptivePicture = ({ set }) => {
  const [xxxs, xxs, xs, s, m] = set;

  return (
    <Picture>
      <Source mediaSize={400} urls={xxxs} />
      <Source mediaSize={500} urls={xxs} />
      <Source mediaSize={600} urls={xs} />
      <Source mediaSize={800} urls={s} />
      <Source mediaSize={1200} urls={m} />
      <img src={m.lcd} alt="banner" />
    </Picture>
  );
};

export default memo(MobileAdaptivePicture);
