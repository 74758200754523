export default function isLatin(value) {
  if (
    !/^[абвгдеёжзийклмнопрстуфхцчшщьыъэюяАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЬЫЪЭЮЯ0-9\s]*$/.test(
      value,
    )
  ) {
    return false;
  }

  return true;
}
