import React from 'react';
import { Link } from 'react-router-dom';

export default class RouterLink extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Link
        {...this.props}
        onClick={(...all) => {
          if (this.props.to === window.location.pathname) {
            if (this.props.onEnterCurrentRoute) {
              this.props.onEnterCurrentRoute();
            } else {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }
          } else if (this.props.onClick) {
            this.props.onClick(...all);
          }
        }}
      >
        {this.props.children}
      </Link>
    );
  }
}
