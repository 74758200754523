import { createActions } from 'redux-actions';
import * as Sentry from '@sentry/browser';

import { fetchCategories } from 'api/products';
import { parseRelativePathSlug } from 'Utils';

export const {
  changePopupVisibility,
  productAddedToCart,
  categoryChanged,
  subcategoryChanged,
  categoriesFetched,
  categoriesInited,
  categoryNotFounded,
  cartCleared,
} = createActions(
  'CHANGE_POPUP_VISIBILITY',
  'PRODUCT_ADDED_TO_CART',
  'CATEGORY_CHANGED',
  'SUBCATEGORY_CHANGED',
  'CATEGORIES_FETCHED',
  'CATEGORIES_INITED',
  'CATEGORY_NOT_FOUNDED',
  'CART_CLEARED',
);

const sendError = (extra, title) => {
  Sentry.withScope((scope) => {
    scope.setExtras(extra);
    scope.setTag('page', 'categories');
    scope.setTag('individual_errors', 'true');

    Sentry.captureEvent({
      message: title || 'Categories actions',
    });
  });
};

export const changePopupState = (name, popupStatus) => (dispatch) =>
  dispatch(changePopupVisibility({ name, popupStatus }));

export const changeCategory = (category) => (dispatch) =>
  dispatch(categoryChanged(category));

export const changeSubCategory = (subCategoryId) => (dispatch) =>
  dispatch(subcategoryChanged(subCategoryId));

export const startFetchCategories = () => async (dispatch) => {
  try {
    const res = await fetchCategories();

    if (res.status !== 200) throw res;

    const normalizedRes = res.data.map((el) => {
      const { id, parentID, ...rest } = el;

      const intId = parseInt(id, 10);
      const intParentId = parseInt(parentID, 10);

      return { id: intId, parentID: intParentId, ...rest };
    });

    dispatch(categoriesFetched(normalizedRes));
  } catch (e) {
    sendError(e, 'Categories fetch action');
  }
};

export const initCategories = (pathname) => (dispatch, getState) => {
  const { categories } = getState().api;
  const { notFound } = getState().products;

  const currentCategory = categories.find((category) => {
    return `/category/${parseRelativePathSlug(category.slug)}` === pathname;
  });

  if (!currentCategory) {
    return dispatch(categoryNotFounded(true));
  }
  if (notFound) {
    dispatch(categoryNotFounded(false));
  }

  dispatch(categoriesInited(currentCategory));
};

export const clearCart = () => (dispatch) => {
  localStorage.setItem('cart', []);

  dispatch(cartCleared());
};
