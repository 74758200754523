import React from 'react';

import { Popup } from '../../UI';

import Main from './Main';

import pushEventOpenGA from '../pushEventOpenGA';

export default class Cart extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      return true;
    }

    return false;
  }

  componentDidUpdate() {
    const { isOpen } = this.props;

    pushEventOpenGA(isOpen, 'Cart');
  }

  render() {
    const headerHeight = this.props.getHeaderHeight();

    return (
      <Popup
        isOpen={this.props.isOpen}
        headerHeight={headerHeight}
        width={this.props.width}
        location="right"
        onClose={this.props.onClose}
      >
        <Main
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          currentPath={this.props.currentPath}
          headerHeight={headerHeight}
        />
      </Popup>
    );
  }
}
