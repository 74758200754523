import React from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';

import { notificationWarn, notificationClose } from '../UI/icons';

const WordImportant = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

const Name = styled.h4`
  margin: 0;
  padding: 0;
  display: none;
  font-size: 12px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    display: initial;
  }
`;

const MobileName = styled.h5`
  margin: 0;
  padding: 0;
  font-size: 8px;
  font-weight: 600;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const NamesWrapper = styled.div`
  max-height: 50px;
  overflow: hidden;
  transition: all 0.3s ease;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.color1};
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.06);
  position: relative;
  border-radius: 20px;
  cursor: ${(props) => (props.isOpen ? 'default' : 'pointer')};
  padding: ${({ isOpen }) => (isOpen ? '12px 8px' : '8px 10px')};
  max-width: 340px;
  user-select: none;
  transition: all 0.3;

  &:after,
  &:before {
    content: '';
    position: absolute;
    right: -8px;
    top: -5px;
    width: 11px;
    height: 11px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    cursor: pointer;
    transition: all 0.18s;
  }

  &:after {
    transform: scale(${(props) => (props.isOpen ? 0 : 1)});
    background-image: url(${notificationWarn});
  }

  &:before {
    transform: scale(${(props) => (props.isOpen ? 1 : 0)});
    background-image: url(${notificationClose});
  }

  ${(props) =>
    props.isOpen &&
    css`
      ${NamesWrapper} {
        max-height: 0;
        opacity: 0;
      }
    `};

  @media screen and (min-width: 1200px) {
    padding: ${({ isOpen }) => (isOpen ? '22px 20px' : '12px 20px')};
    box-shadow: 10px 10px 15px 2px rgba(0, 0, 0, 0.04);

    &:after,
    &:before {
      right: -10px;
      top: -8px;
      width: 14px;
      height: 14px;
    }
  }
`;

const Icon = styled.img`
  max-width: 40px;
  margin-bottom: 10px;
  transform: scale(0);
  transition: 0.3s ease 0.3s;

  @media screen and (min-width: 1200px) {
    margin-right: 15px;
    max-width: 48px;
    margin-bottom: 0;
  }
`;

const Text = styled.span`
  margin-bottom: ${({ mb }) => mb || 0}px;
`;

const Content = styled.div`
  max-height: ${(props) => (props.isOpen ? '370px' : 0)};
  max-width: ${(props) => (props.isOpen ? '140px' : 0)};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 10px;
  font-weight: 600;

  ${(props) =>
    !props.isOpen &&
    css`
      transition: max-width 0.4s ease 0.17s, max-height 0.4s ease 0s,
        opacity 0.35s;
    `};

  ${(props) =>
    props.isOpen &&
    css`
      transition: max-width 0.4s ease 0s, max-height 0.4s ease 0.13s,
        opacity 0.4s linear 0.3s;

      ${Icon} {
        transform: scale(1);
      }
    `};

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
    max-width: ${(props) => (props.isOpen ? '300px' : 0)};
    max-height: ${(props) => (props.isOpen ? '360px' : 0)};
    font-size: 12px;
    font-weight: 500;
  }
`;

const EmailLink = styled.a.attrs(() => ({
  href: 'mailto:info@hermitageshop.com',
}))`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
`;

const PrimaryText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

export default class NewYearHolidays extends React.Component {
  state = {
    isOpen: true,
  };

  shouldComponentUpdate(_, nextState) {
    if (!isEqual(nextState, this.state)) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <Wrapper
        isOpen={this.state.isOpen}
        onClick={() =>
          this.setState({ isOpen: !this.state.isOpen }, () => {
            if (window.ga) {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'Notification: Beta',
                eventAction: 'click',
              });
            }
          })
        }
      >
        <NamesWrapper>
          <Name>
            Режим работы в{' '}
            <WordImportant>праздничные и нерабочие дни</WordImportant>
          </Name>

          <MobileName>
            Режим работы
            <br /> в <WordImportant>праздничные и нерабочие дни</WordImportant>
          </MobileName>
        </NamesWrapper>

        <Content isOpen={this.state.isOpen}>
          <Text>
            <PrimaryText>Уважаемые покупатели!</PrimaryText><br />
            <br />
            <br />
            Обращаем ваше внимание, что в связи с загруженностью служб курьерской доставки в новогодний период, при оформлении заказа начиная <PrimaryText>с 24 декабря</PrimaryText>, мы не можем гарантировать доставку в этом году.
            <br />
            <br />
			Все заказы, оформленные <PrimaryText>после 25 декабря</PrimaryText>, будут доставлены после <PrimaryText>6 января</PrimaryText>.
			<br />
            <br />
            Заказы, оформленные <PrimaryText>после 14:00 30 декабря</PrimaryText>, будут собраны и переданы на доставку начиная <PrimaryText>с 5 января</PrimaryText>.   
			<br />
            <br />
            Доставка заказов в пункт выдачи будет осуществляться в обычном режиме <PrimaryText>до 31 декабря</PrimaryText>.
            <br />
            <br />
			<PrimaryText>С 10 января</PrimaryText> склад и офис Эрмитажного магазина работают в стандартном режиме.
		           
          </Text>
        </Content>
      </Wrapper>
    );
  }
}
