import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import { useWindowSize } from 'Utils';

import './slider.css';

import {
  PhotoWrapper,
  Slide,
  SlideInner,
  Photo,
  Gallery,
  ArrowLeft,
  ArrowRight,
  GalleryWrapper,
} from './styled';

const sliderSettings = {
  dots: false,
  arrows: false,
  className: 'cardSlider',
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: 'progressive',
};

const DesktopSlider = ({ gallery }) => {
  const sliderRef = React.useRef(null);

  const nextSlide = () => sliderRef.current.slickNext();
  const prevSlide = () => sliderRef.current.slickPrev();

  return (
    <Gallery>
      <ArrowLeft
        onClick={prevSlide}
        isVisible={gallery.length > 1}
        data-type="arrow"
      />

      <Slider {...sliderSettings} ref={sliderRef}>
        {gallery &&
          gallery.map((photo, id) => (
            <Slide>
              <SlideInner>
                <PhotoWrapper key={`galleryPhoto_id_${id}`}>
                  <Photo src={photo} />
                </PhotoWrapper>
              </SlideInner>
            </Slide>
          ))}
      </Slider>

      <ArrowRight
        onClick={nextSlide}
        isVisible={gallery.length > 1}
        data-type="arrow"
      />
    </Gallery>
  );
};

const MobileSlider = ({ gallery }) => (
  <PhotoWrapper>
    <Photo src={gallery[0]} />
  </PhotoWrapper>
);

const PhotoSlider = ({ photos }) => {
  const { width } = useWindowSize();

  const isDesktop = width > 1200;

  return (
    <GalleryWrapper>
      {isDesktop && <DesktopSlider gallery={photos} />}
      {!isDesktop && <MobileSlider gallery={photos} />}
    </GalleryWrapper>
  );
};

export default PhotoSlider;
