import React from 'react';
import styled, { css } from 'styled-components/macro';

import { arrowPagination, arrowPaginationActive } from './icons';
import Preloader from './Preloader';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

const BaseArrow = styled.div`
  width: 10px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
  position: relative;
  top: -2px;
  transition: all 0.18s;
`;

const Back = styled(BaseArrow)`
  background-image: url(${arrowPagination});
  margin-right: 60px;
  opacity: ${props => (props.isDisable ? 0 : 1)};
`;

const Next = styled(BaseArrow)`
  background-image: url(${arrowPaginationActive});
  margin-left: 60px;
  opacity: ${props => (props.isDisable ? 0 : 1)};
`;

const Points = styled.div`
  display: flex;
  align-items: center;
`;

const Point = styled.span`
  position: relative;
  z-index: 2;
  font-size: 15px;
  font-weight: 500;
  padding: 0 10px;
  color: ${props => (props.isActive ? '#FFF' : '#656565')};
  cursor: ${props => (props.isDisable ? 'default' : 'pointer')};
  user-select: none;
  transition: all 0.18s;

  &:before {
    content: '';
    width: 30px;
    height: 30px;
    background-color: #e71d85;
    border-radius: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    opacity: ${props => (props.isActive ? 1 : 0)};
    transform: scale(${props => (props.isActive ? 1 : 0.75)});
    transition: all 0.18s;
  }

  &:last-child {
    margin-right: 0;
  }

  ${props => !props.isDisable
    && css`
      &:hover {
        color: #e71d85;
        /* color: #fff;

        &:before {
          opacity: 1;
        } */
      }
    `};
`;

export default class Pagination extends React.Component {
  state = {
    loading: true,
  };

  componentDidUpdate() {
    const { totalamount, limit, offset } = this.props;

    if (
      Number.isInteger(totalamount)
      && Number.isInteger(limit)
      && Number.isInteger(offset)
      && totalamount > 0
    ) {
      if (this.state.loading) {
        this.setState({ loading: false });
      }
    } else if (!this.state.loading) {
      this.setState({ loading: true });
    }
  }

  render() {
    const { totalamount, limit, offset } = this.props;

    const startPageOptions = {
      limit,
      offset: 0,
      nextPage: 1,
    };
    const endPage = Math.ceil(totalamount / limit);
    const endPageOffset = (Math.ceil(totalamount / limit) - 1) * limit;
    const endPageOptions = {
      offset: endPageOffset,
      limit,
      nextPage: endPage,
    };

    // const currentPage = endPage - Math.floor((totalamount - offset) / limit) || 1;
    const currentPage = Math.floor(offset / limit) + 1 || 1;
    const beforeCurrentPage = currentPage === 1 ? 0 : currentPage - 1;
    const afterCurrentPage = currentPage === endPage ? 0 : currentPage + 1;
    const nextPageOptions = {
      limit,
      offset: currentPage * limit,
      nextPage: currentPage + 1,
    };
    const prevPageOptions = {
      limit,
      offset: currentPage * limit - limit * 2,
      nextPage: currentPage - 1,
    };

    if (this.state.loading) {
      return (
        <Wrapper>
          <Preloader />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Back
          isDisable={currentPage <= 1}
          onClick={() => (currentPage <= 1 ? null : this.props.onChangePage(prevPageOptions))
          }
        />
        <Points>
          {beforeCurrentPage !== 0 && (
            <Point onClick={() => this.props.onChangePage(startPageOptions)}>
              1
            </Point>
          )}

          {beforeCurrentPage - 2 > 0 && beforeCurrentPage !== 0 ? (
            <Point isDisable>...</Point>
          ) : (
            ''
          )}

          {beforeCurrentPage - 1 > 0 && beforeCurrentPage !== 0 && (
            <Point onClick={() => this.props.onChangePage(prevPageOptions)}>
              {beforeCurrentPage}
            </Point>
          )}

          <Point isActive>{currentPage}</Point>

          {afterCurrentPage < endPage && afterCurrentPage !== 0 && (
            <Point onClick={() => this.props.onChangePage(nextPageOptions)}>
              {afterCurrentPage}
            </Point>
          )}

          {afterCurrentPage + 1 < endPage && afterCurrentPage !== 0 ? (
            <Point isDisable>...</Point>
          ) : (
            ''
          )}
          {endPage !== currentPage && (
            <Point onClick={() => this.props.onChangePage(endPageOptions)}>
              {endPage}
            </Point>
          )}
        </Points>
        <Next
          isDisable={currentPage >= endPage}
          onClick={() => (currentPage >= endPage
            ? null
            : this.props.onChangePage(nextPageOptions))
          }
        />
      </Wrapper>
    );
  }
}
