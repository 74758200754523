import React from 'react';
import isEqual from 'lodash/isEqual';

import { addEventWheel } from 'Utils';

import { Wrapper } from './styled';

import Top from './Top';
import Bottom from './Bottom';

class FooterMain extends React.Component {
  footerDOM = null;

  componentDidMount() {
    this.props.setRefs(this.footerDOM);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      (!this.props.isHiddenDesktop && nextProps.isHiddenDesktop) ||
      // || !isEqual(nextProps.currentShift, this.props.currentShift)
      !isEqual(this.state, nextState)
    ) {
      return true;
    }

    return false;
  }

  componentDidUpdate() {
    if (this.props.isOpenPopup) {
      addEventWheel(true, this.footerDOM, this.onWheelOnOpenedPopup);
    } else {
      addEventWheel(false, this.footerDOM, this.onWheelOnOpenedPopup);
    }
  }

  onWheelOnOpenedPopup = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <>
        <Wrapper
          id="footer"
          ref={(e) => {
            this.footerDOM = e;
          }}
          isOpenPopup={this.props.isOpenPopup}
          from={this.props.from}
          isHiddenDesktop={this.props.pathname === '/'}
          // currentShift={
          //   this.props.currentShift ? this.props.currentShift : { x: 0, y: 0 }
          // }
        >
          <Top
            onChangePopup={this.props.onChangePopup}
            onChangeModal={this.props.onChangeModal}
          />

          <Bottom onChangeModal={this.props.onChangeModal} />
        </Wrapper>
      </>
    );
  }
}

export default FooterMain;
