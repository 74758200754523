export default function allPositions(cart) {
  let allQuantity = 0;

  cart.map((product) => {
    allQuantity += product.quantity;
    return product;
  });

  return allQuantity;
}
