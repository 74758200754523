import React from 'react';
import styled from 'styled-components/macro';

import List from './List';

const Navigation = styled.div.attrs(({ headerHeight }) => ({
  style: {
    maxHeight: `calc(100vh - ${headerHeight}px)`,
  },
}))`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

export default props => (
  <Navigation
    headerHeight={props.headerHeight}
    ref={(el) => {
      props.setScrollableContainer(el);
    }}
  >
    <List
      categories={props.categories}
      apiInstance={props.apiInstance}
      pathScheme={props.pathScheme}
      currentPath={props.currentPath}
      onClose={props.onClose}
    />
  </Navigation>
);
