import merge from 'lodash/merge';

import CHANGE_MODAL from './actions';

const initialModals = {
  personalData: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },

  cookies: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },

  excursionsInfo: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },

  forgetPassword: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },

  oferta: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },

  subscribe: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },

  exemptionInfo: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },

  vouchersInfo: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },
  toursAgreement: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },
  purchaseInstruction: {
    isOpen: false,
    openingPoint: {
      x: 0,
      y: 0,
    },
  },
};

export default function reducer(state = initialModals, action) {
  if (action.type === CHANGE_MODAL) {
    const modal = state[action.payload.name];

    if (!modal) {
      return state;
    }

    if (action.payload === false) {
      return initialModals;
    }

    const newIsOpenProperty = !modal.isOpen;

    // legacy property "openingPoint" for animate opening modals from click point
    const newModalState = {
      [action.payload.name]: {
        isOpen: newIsOpenProperty,
        openingPoint: action.payload.openingPoint ||
          modal.openingPoint || { x: 0, y: 0 },
      },
    };

    if (window.ga) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: `Modal: ${action.payload.name}`,
        eventAction: newIsOpenProperty ? 'open' : 'close',
        nonInteraction: true,
      });
    }

    return merge({}, state, newModalState);
  }

  return state;
}
