import { PHOTO_SIZES } from '../../../constants';

export default () => {
  const { xxs, xs, s, m, l, xl, xxl, full } = PHOTO_SIZES;

  if (typeof window === 'undefined') return xxs;

  const windowWidth = window.innerWidth;

  if (windowWidth > 2400) return full;
  if (windowWidth > 2100) return xxl;
  if (windowWidth > 1800) return xl;
  if (windowWidth > 1500) return l;
  if (windowWidth > 1200) return m;
  if (windowWidth > 1000) return l;
  if (windowWidth > 800) return m;
  if (windowWidth > 600) return s;
  if (windowWidth > 400) return xs;

  return xxs;
};
