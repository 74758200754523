import minBy from 'lodash/minBy';

export default function getMinimumDiscountOption(options) {
  let minimumDiscountOption = {
    price: 0,
    discount: 0,
  };

  options.map((option) => {
    const minimumDiscount = minBy(option.values, (optionValue) => {
      const discount =
        parseInt(optionValue.discount, 10) > 0 &&
        parseInt(optionValue.instockquantity, 10) > 0;

      if (discount) {
        return parseInt(optionValue.discount, 10);
      }

      return NaN;
    });

    minimumDiscountOption = {
      id: option.id,
      optionValue: minimumDiscount,
      price: minimumDiscount.price,
      discount: minimumDiscount.discount,
      discountPercent: minimumDiscount.discountpercent,
    };

    return option;
  });

  return minimumDiscountOption;
}
