import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { isDefaultThemeSelector } from 'common/selectors';

import { plus, minus, darkPlus, darkMinus } from './icons';

const Nums = styled.div`
  display: flex;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  max-width: 100%;
`;

const Minus = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 22px;
  cursor: pointer;
  visibility: ${(props) => (props.isDisabled ? 'hidden' : 'visible')};
  opacity: ${(props) => (props.isDisabled ? 0 : 1)};
  width: 14px;
  height: 14px;
  background-image: url(${({ isDefaultTheme }) =>
    isDefaultTheme ? minus : darkMinus});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: all 0.18s;
  position: relative;
  top: 1px;
`;

const Plus = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 22px;
  cursor: pointer;
  visibility: ${(props) => (props.isDisabled ? 'hidden' : 'visible')};
  opacity: ${(props) => (props.isDisabled ? 0 : 1)};
  width: 14px;
  height: 14px;
  background-image: url(${({ isDefaultTheme }) =>
    isDefaultTheme ? plus : darkPlus});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: all 0.18s;
  position: relative;
  top: 1px;
`;

const Num = styled.span`
  font-size: 13px;
  font-weight: 500;
  margin: 0 4px;
  padding-top: 4px;
  min-width: 10px;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    margin: 0 10px;
  }
`;

class NumSlider extends React.Component {
  state = {};

  render() {
    const { isDefaultTheme } = this.props;

    let { isDisabled } = this.props;
    const nums = parseInt(this.props.nums, 10);

    let max = this.props.max ? parseInt(this.props.max, 10) : -1;

    if (this.props.max && max <= 0) {
      max = nums;
      isDisabled = true;
    }

    let min = this.props.min ? parseInt(this.props.min, 10) : 0;

    if (this.props.min && min <= 0) {
      min = 0;
    }

    if (min >= max) {
      isDisabled = true;
    }

    return (
      <Nums>
        <Minus
          isDisabled={isDisabled || nums === min}
          onClick={() => {
            if (nums - 1 >= min) {
              this.props.onChangeQuantity(nums - 1);
            }
          }}
          isDefaultTheme={isDefaultTheme}
        />

        <Num>{nums}</Num>

        <Plus
          isDisabled={isDisabled || nums === max}
          onClick={() => {
            if (max > 0 && nums + 1 <= max) {
              this.props.onChangeQuantity(nums + 1);
            } else if (max < 0) {
              this.props.onChangeQuantity(nums + 1);
            }
          }}
          isDefaultTheme={isDefaultTheme}
        />
      </Nums>
    );
  }
}

const mapStateToProps = (state) => ({
  isDefaultTheme: isDefaultThemeSelector(state),
});

export default connect(mapStateToProps)(NumSlider);
