import styled, { css } from 'styled-components';

export const DiscountSquare = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
  border-radius: 40px;
  z-index: 20;

  ${({ isMobile }) =>
    isMobile
      ? css`
          top: -15px;
          right: -15px;
        `
      : css`
          top: -75px;
          left: -70px;
        `};

  background-color: ${({ theme }) => theme.colors.primary};
`;

export const Discount = styled.span`
  color: #fff;
  font-size: 26px;
  line-height: 29px;
`;

export const DiscountLine = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  left: 0;
  right: 0;
  top: 14px;
  position: absolute;
`;

export const OldPrice = styled.span`
  position: relative;
  padding: 0 7px;
  font-size: 24px;
  line-height: 29px;
  color: #d4d4d4;
`;

export const NewPrice = styled(OldPrice)`
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 10px;
`;
