import React from 'react';
import isEqual from 'lodash/isEqual';

import { Popup } from '../../UI';

import Main from './Main';

import pushEventOpenGA from '../pushEventOpenGA';

class MobileMenu extends React.Component {
  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    if (
      this.props.isOpen !== nextProps.isOpen ||
      !isEqual(this.props.categories, nextProps.categories)
    ) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { isOpen: prevIsOpen } = prevProps;

    if (isOpen && !prevIsOpen) {
      pushEventOpenGA(isOpen, 'Mobile Menu', false);
    } else if (!isOpen && prevIsOpen) {
      pushEventOpenGA(isOpen, 'Mobile Menu', false);
    }
  }

  render() {
    const headerHeight = this.props.getHeaderHeight();

    return (
      <Popup
        isOpen={this.props.isOpen}
        headerHeight={headerHeight}
        width={this.props.width}
        location="left"
        onClose={this.props.onClose}
      >
        <Main
          apiInstance={this.props.apiInstance}
          categories={this.props.categories}
          onChangePopup={this.props.onChangePopup}
          onClose={this.props.onClose}
          pathScheme={this.props.pathScheme}
          currentPath={this.props.currentPath}
        />
      </Popup>
    );
  }
}

export default MobileMenu;
