import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from './i18n.resources';

const language = process.env.REACT_APP_REGION || 'ru';

i18n.use(initReactI18next).init({
  fallbackLng: language,
  ns: ['header', 'common'],
  lng: language,
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
