import { handleActions } from 'redux-actions';

import { categoriesFetched } from 'common/actions';

const initialPopups = {
  categories: [],
};

export default handleActions(
  {
    [categoriesFetched]: (state, { payload }) => ({
      ...state,
      categories: payload,
    }),
  },
  initialPopups,
);
