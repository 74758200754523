import { createSelector } from 'reselect';

const cartSelector = (state) => state.cart;

export const productsSelector = createSelector(
  cartSelector,
  (cart) => cart.products,
);

export const cartLoadingSelector = createSelector(
  cartSelector,
  (cart) => cart.cartIsLoading,
);
