import React from 'react';
import styled from 'styled-components/macro';
import isEqual from 'lodash/isEqual';

import NewYearHolidays from './NewYearHolidays';

const Wrapper = styled.div.attrs(({ isVisible }) => ({
  style: {
    display: isVisible ? 'block' : 'none',
  },
}))`
  position: fixed;
  z-index: 60;
  left: 10px;
  bottom: 10px;
  color: ${({ theme }) => theme.colors.color12};
  transition: all 0.3s;

  @media screen and (min-width: 1200px) {
    left: 40px;
    bottom: 40px;
  }
`;

export default class Notifications extends React.Component {
  state = {
    isVisible: !this.props.isOpenPopup,
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.onScroll);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.isOpenPopup !== nextProps.isOpenPopup ||
      !isEqual(this.state, nextState)
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onScroll);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      (props.isOpenPopup && state.isVisible) ||
      props.pathScheme === '/product/:id'
    ) {
      // this.setState({ isVisible: false });
      return { isVisible: false };
    }

    return null;
  }

  onScroll = () => {
    const currentScrollBottom =
      document.documentElement.scrollTop + window.innerHeight;

    const isVisibleFooter =
      currentScrollBottom >=
      document.documentElement.scrollHeight - this.props.getFooterHeight();

    if (isVisibleFooter && this.state.isVisible) {
      this.setState({ isVisible: false });
    } else if (!isVisibleFooter && !this.state.isVisible) {
      this.setState({
        isVisible: true,
      });
    }
  };

  render() {
    return (
      <Wrapper isVisible={this.state.isVisible}>
        <NewYearHolidays />
      </Wrapper>
    );
  }
}
