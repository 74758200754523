import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { connect } from 'react-redux';

import { addEventWheel } from 'Utils';
import { isDefaultThemeSelector } from 'common/selectors';

import { modalsTypes } from '../../Modals/propTypes';
import { cross, darkCross } from '../icons';

const ModalBackground = styled.div`
  position: fixed;
  z-index: ${(props) => props.zIndex};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(101, 101, 101, 0.8);
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  cursor: pointer;
  transition: opacity 0.18s ease;
`;

const PreWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  max-width: 100%;
  max-height: 100%;
  z-index: ${(props) => props.zIndex};
  opacity: 0;
  overflow-y: auto;
  visibility: hidden;
  transition: opacity 0.3s ease;

  ${(props) =>
    props.isOpen &&
    css`
      visibility: visible;
      opacity: 1;
    `};
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 130;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Cross = styled.button`
  outline: none;
  border: none;
  background: none;
  padding: 0;
  background-image: url(${({ isDefaulttheme }) =>
    isDefaulttheme ? cross : darkCross});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  @media (max-width: 1230px) {
    position: fixed;
  }
`;

class EmptyModal extends React.Component {
  zIndexBackground = 100;

  step = 10;

  zIndexModal = 110; // 100 + step

  constructor(props) {
    super(props);

    if (!this.canUseDOM()) {
      return null;
    }

    this.modal = document.createElement('div');
  }

  componentDidMount() {
    const modalRoot = document.getElementById('modal-root');
    modalRoot.appendChild(this.modal);
    addEventWheel(true, this.modalBackground, this.onWheel);
  }

  shouldComponentUpdate(nextProps) {
    const { isCustomUpdateComponent, isOpen } = this.props;

    const { modals } = nextProps;

    if (!isCustomUpdateComponent) {
      if (nextProps.isOpen === isOpen) {
        return false;
      }
    }

    if (nextProps.isOpen && !isOpen) {
      const numModals = this.getNumOpenedModals(modals);

      this.zIndexBackground =
        this.zIndexBackground +
        (this.zIndexModal - this.zIndexBackground) +
        this.step * numModals;
      this.zIndexModal = this.zIndexModal + this.step * numModals + 5;
    }

    if (!nextProps.isOpen) {
      this.zIndexBackground = 100;
      this.zIndexModal = 110;
    }

    return true;
  }

  componentWillUnmount() {
    addEventWheel(false, this.modalBackground, this.onWheel);
  }

  canUseDOM = () =>
    !!(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    );

  onWheel = (e) => {
    e.preventDefault();
  };

  getNumOpenedModals = (modals) => {
    const nameModals = Object.keys(modals);

    const openedModals = nameModals.filter((name) => modals[name].isOpen)
      .length;

    return openedModals;
  };

  render() {
    if (!this.canUseDOM()) {
      return null;
    }

    const {
      isOpen,
      onClose,
      openingPoint,
      children,
      isDefaultTheme,
    } = this.props;

    const html = (
      <>
        <ModalBackground
          isOpen={isOpen}
          zIndex={this.zIndexBackground}
          onClick={onClose}
          ref={(e) => (this.modalBackground = e)}
        />

        <PreWrapper
          isOpen={isOpen}
          zIndex={this.zIndexModal}
          openingPoint={openingPoint}
        >
          <Wrapper isOpen={isOpen}>
            <Cross onClick={onClose} isDefaultTheme={isDefaultTheme} />
            {children}
          </Wrapper>
        </PreWrapper>
      </>
    );

    return ReactDOM.createPortal(html, this.modal);
  }
}

EmptyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openingPoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  children: PropTypes.element.isRequired,
  isCustomUpdateComponent: PropTypes.bool,
  modals: modalsTypes.isRequired,
  isDefaultTheme: PropTypes.bool.isRequired,
};

EmptyModal.defaultProps = {
  openingPoint: {
    x: 0,
    y: 0,
  },
  isCustomUpdateComponent: false,
};

export default connect((store) => ({
  modals: store.modals,
  isDefaultTheme: isDefaultThemeSelector(store),
}))(EmptyModal);
