import { TOKEN_EXPIRATION_TIME } from '../constants';

export const isExpired = (loginDate, currentDate) => {
  return currentDate - loginDate >= TOKEN_EXPIRATION_TIME;
};

const checkAuth = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const loginDate = JSON.parse(localStorage.getItem('login_date'));
  const currentDate = Date.now();

  if ((user && !loginDate) || isExpired(loginDate, currentDate)) {
    localStorage.removeItem('user');
    localStorage.removeItem('login_date');
  }
};

export default checkAuth;
