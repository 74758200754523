import React from 'react';
import styled from 'styled-components/macro';

import { Button, Modal } from '../UI';

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 31px;
  font-weight: 700;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
`;

const Info = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
`;

const BrandColor = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

const TextAccent = styled.strong`
  font-weight: 400;

  color: ${({ theme }) => theme.colors.primary};
`;

export default class PurchaseInstruction extends React.Component {
  componentDidMount() {}

  render() {
    const { isOpen, onClose, openingPoint } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose} openingPoint={openingPoint}>
        <>
          <Info>
            <p>
              <BrandColor>
                <strong>1. Как купить</strong>
              </BrandColor>
              <br />
              <br />
              Для оформления заказа на обзорную экскурсию, пожалуйста, выберите
              день проведения экскурсии, время, а также количество посетителей
              (до 6 гостей).
              <br />
              <br />
              Затем, кликните «Оформить заказ».
              <br />
              <br />
              После ввода контактных данных, пожалуйста, кликните «Купить».
              <br />
              <br />
              Вы будете перенаправлены на сайт платежной системы «PayU» для
              оплаты заказа.
              <br />
              <br />
              После оплаты, на Вашу электронную почту поступит подтверждение
              оплаты заказа, а также электронный ваучер на экскурсионное
              обслуживание.
              <br />
              <br />В случае, если ваучер не поступил, пожалуйста, свяжитесь со
              службой поддержки по номеру телефона:{' '}
              <TextAccent>8 (812) 429-74-07</TextAccent>
              <br />
              <br />
              Также, вы можете направить запрос на следующий адрес эл. почты:{' '}
              <TextAccent>info@hermitageshop.com</TextAccent>
              <br />
              <br />
              Билеты на обзорную экскурсию приобретаются не позднее, чем за 3
              рабочих дня музея до даты проведения экскурсии. Внесение изменений
              в заказ возможно не позднее, чем за один рабочий день музея до
              даты проведения экскурсии. Понедельники, первое января и девятое
              мая – дни, когда музей не работает.
            </p>
            <p>
              <br />
              <BrandColor>
                <strong>
                  2. Как использовать (место и время встречи, документы, время,
                  другие детали)
                </strong>
              </BrandColor>
              <br />
              <br />
              Покупка обзорной экскурсии онлайн позволяет посетить экспозиции
              главного музейного комплекса Эрмитажа или Главного штаба в
              указанные на электронном ваучере дату и время.
              <br />
              <br />
              Посетитель должен иметь с собой распечатанный ваучер, поступивший
              на электронную почту после оплаты, с указанием имени, фамилии,
              отчества покупателя, а также документ, удостоверяющий личность.
              <br />
              <br />
              Если посетитель не имеет распечатанного ваучера, то необходимо
              предъявить документ, удостоверяющий личность покупателя, а также
              электронную версию ваучера или номер заказа. Билеты на экскурсию
              выдаются администратором при наличии этих документов
              непосредственно перед началом экскурсии.
              <br />
              <br />
              Экскурсионное обслуживание осуществляется для малых групп (1-6
              человек).
              <br />
              <br />
              Место встречи с администратором при заказе обзорной экскурсии: под
              въездной аркой Зимнего дворца, у входа в Экскурсионное бюро (с 1
              Июня по 1 Сентября), и у билетных касс главного входа Зимнего
              дворца (с 1 сентября по 1июня).
              <br />
              <br /> Сбор экскурсионной группы производится за 15 минут до
              начала экскурсии в период с 1 июня по 1 сентября и за 30 минут до
              начала экскурсии – с 1 сентября по 1 июня.
              <br />
              <br />
              Каждая экскурсия длится 2 часа 15.
            </p>

            <p>
              <BrandColor>
                <strong>3. Как отменить и вернуть деньги</strong>
              </BrandColor>
              <br />
              <br />
              Если Ваши планы изменились, вы можете отменить заказанную
              экскурсию.
              <br />
              <br />
              Для отмены заказа вам необходимо отправить письмо на адрес
              электронной почты службы поддержки info@hermitageshop.com с
              указанием следующих данных:
              <br />
              <br />
              - номер заказа; фамилия, имя и отчество заказчика;
              <br />
              - номер телефона, указанный при оформлении заказа;
              <br />
              - причина отмены заказа.
              <br />
              <br />
              В отношении экскурсионных заказов применяются следующие правила
              аннулирования:
              <br />
              <br />
              При отмене заказа не менее, чем за 3 дня до даты проведения
              экскурсии, возврат средств осуществляется за вычетом стоимости
              обработки заказа – 300 руб. + 100 руб. за каждый проданный
              экскурсионный билет.
              <br />
              Частичная отмена заказа невозможна.
              <br />
              Возврат средств можно осуществить только за все билеты, указанные
              в одном заказе.
              <br />
              Возврат производится обратной транзакцией на карту, которая была
              использована при оплате.
            </p>

            <p>
              <br />
              <br />
              <strong>
                <BrandColor>4. Общие вопросы (faq)</BrandColor>
              </strong>
              <br />
              <br />
              <Description>
                Информация об индивидуальных обзорных экскурсиях
              </Description>
              <br />
              <br />
              1. Заказ индивидуальной обзорной экскурсии онлайн позволит вам
              планировать свой визит в музей заранее и в определенное время,
              избежать очереди в кассу, а также самостоятельно формировать
              состав группы от одного до шести человек;
              <br />
              <br />
              2. Заказ экскурсии онлайн дает право на посещение экскурсии в
              указанные в ваучере день и время.
              <br />
              <br />
              3. При заказе экскурсии онлайн, вы получите билеты непосредственно
              перед ее началом от администратора; для получения билетов вам
              необходимо иметь при себе распечатанный ваучер и документ,
              удостоверяющий личность заказчика. Если посетитель не имеет
              распечатанного ваучера, то необходимо предъявить документ,
              удостоверяющий личность покупателя, а также электронную версию
              ваучера или номер заказа. Билеты на экскурсию выдаются
              администратором при наличии этих документов непосредственно перед
              началом экскурсии.
              <br />
              <br />
              4. Администратор поможет вам избежать очереди на входе в музей,
              проведет вас к вашим местам в гардеробе и к месту начала
              экскурсии, а также познакомит вас с вашим гидом и вручит билет
              каждому посетителю. Билет имеет индивидуальный штрих-код, который
              необходимо просканировать при проходе через турникет;
              <br />
              <br />
              5. Заказ экскурсии возможен не позднее, чем за 3 рабочих дня музея
              до даты проведения экскурсии; изменения в заказе возможны не
              позднее, чем за 1 рабочий день музея до даты проведения экскурсии;
              <br />
              <br />
              6. Каждая экскурсия длится 2 часа 15 мин. Количество человек в
              группе – от одного до шести;
              <br />
              <br />
              7. Дети до 18 лет обслуживаются бесплатно. При заказе экскурсии
              необходимо указать количество детских билетов. Детские билеты
              входят в общее количество заказанных билетов.
              <br />
              <br />
              8. Сбор экскурсионной группы производится за 15 минут до начала
              экскурсии в период с 1 июня по 1 сентября, и за 30 минут в период
              с 1 сентября по 1 июня;
              <br />
              <br />
              9. В случае опоздания посетителя менее, чем на 1 час, при
              предварительном уведомлении, посетитель обслуживается,
              соответственно оставшемуся времени экскурсии. В случае опоздания
              посетителя более, чем на 1 час без предварительного уведомления,
              посетитель не обслуживается, услуга считается оказанной;
              <br />
              <br />
              10. Заказ индивидуальных обзорных экскурсий в Эрмитаже онлайн.
              <br />
              <br />
              Приобрести электронные билеты на обзорную экскурсию для малой
              группы (1-6 человек) вы можете в разделе «Посещение музея». Оплата
              билетов осуществляется с помощью банковской карты онлайн. К оплате
              принимаются карты VISA, Mastercard, МИР.
              <br />
              <br />
              <Description>Обратите внимание:</Description>
              <br />
              <br />
              — при заказе экскурсии для другого человека, при вводе данных о
              посетителе вам необходимо указать фамилию, имя и отчество того,
              кто непосредственно собирается посетить музей.
              <br />
              <br />
              — при заказе экскурсии для нескольких посетителей, вам необходимо
              при вводе данных указать фамилию, имя и отчество только одного из
              посетителей.
              <br />
              <br />
              Внимание! Входной билет дает право на однократное посещение музея.
              Пройдя через турникет на выход, вы не сможете вернуться на
              экспозицию по тому же билету.
              <br />
              <br />
              <Description>Место сбора экскурсионных групп:</Description>
              <br />
              <br />
              Для обзорной экскурсии по Главному музейному комплексу: под
              въездной аркой Зимнего дворца, у входа в Экскурсионное бюро, в
              период с 1 Июня по 1 Сентября, и у билетных касс главного входа
              Зимнего дворца – в период с 1 сентября по 1июня.
              <br />
              <br />
              Уточнить информацию о месте сбора экскурсионных групп можно по
              телефону администратора музея{' '}
              <TextAccent>(812) 710-90-79</TextAccent>, или же в Службе
              поддержки – телефон <TextAccent>8(812) 429-74-07.</TextAccent>
              <br />
              <br />
              Если после окончания экскурсии Вы хотите продолжить посещение
              музея, и принимая во внимание, что Ваш билет позволяет только
              однократно посетить все экспозиции Главного музейного комплекса
              или Главного штаба, НЕ ВЫХОДИТЕ через турникет.
            </p>
          </Info>

          <ButtonWrap>
            <Button padding="17px 70px" onClick={onClose}>
              Всё понятно
            </Button>
          </ButtonWrap>
        </>
      </Modal>
    );
  }
}
