import React from 'react';
import styled from 'styled-components/macro';

import MainCategories from './MainCategories';
import SubCategories from './SubCategories';

const Root = styled.div``;

const Inner = styled.div`
  width: 100%;
`;

const DetailMenu = (props) => (
  <Root>
    <Inner>
      <MainCategories pathname={props.pathname} />

      <SubCategories
        pathname={props.pathname}
        parentCategorySlug={props.parentCategorySlug}
      />
    </Inner>
  </Root>
);

export default DetailMenu;
