import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import { Link as RLink } from 'react-router-dom';

import { Preloader, Vk, Facebook, Instagram } from 'UI';
import { parseRelativePathSlug } from 'Utils';
import { changeCategory } from 'common/actions';

export const Point = styled(RLink)`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  cursor: pointer;
  transition: color 0.18s;
  text-decoration: none;

  @media screen and (min-width: 1200px) {
    font-size: 19px;
  }
`;

export const PointWrap = styled.div`
  display: flex;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  ${Point} {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : 'inherit'};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px;
  transition: all 0.55s;
  border-bottom: 1px solid ${({ theme }) => theme.colors.color4};

  @media screen and (min-width: 1200px) {
    padding: 40px;
  }
`;

const Important = styled.div`
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.color4};
  border-bottom: 1px solid ${({ theme }) => theme.colors.color4};

  @media screen and (min-width: 1200px) {
    border-top: none;
    padding: 40px;
  }
`;

const SocialLinks = styled.div`
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    padding: 20px;
    justify-content: flex-start;
  }
`;

class List extends React.Component {
  state = {
    parentid: 0,
  };

  shouldComponentUpdate(nextProps) {
    if (
      this.props.currentPath !== nextProps.currentPath ||
      !isEqual(this.props.categories, nextProps.categories)
    ) {
      return true;
    }

    return false;
  }

  checkCurrentPage = (path) => {
    const { currentPath, onClose, categories, changeCategory } = this.props;

    if (path === currentPath) {
      onClose();
    }

    if (window.ga) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Popup Menu',
        eventAction: 'click',
        eventLabel: path,
      });
    }

    const currentCategory = categories.find((category) => {
      return (
        `/category/${parseRelativePathSlug(category.slug)}` === currentPath
      );
    });

    if (currentCategory) {
      changeCategory(currentCategory);
    }
  };

  render() {
    const hits = find(
      this.props.categories,
      (category) => parseInt(category.id, 10) === 298,
    );

    const newest = find(
      this.props.categories,
      (category) => parseInt(category.id, 10) === 299,
    );

    const collections = find(
      this.props.categories,
      (category) => parseInt(category.id, 10) === 300,
    );

    const sale = find(
      this.props.categories,
      (category) => parseInt(category.id, 10) === 131,
    );

    return (
      <>
        {this.props.categories.length > 0 ? (
          <>
            <Important>
              {hits && (
                <PointWrap
                  isActive={
                    this.props.currentPath ===
                    `/category/${parseRelativePathSlug(hits.slug)}`
                  }
                  onClick={() =>
                    this.checkCurrentPage(
                      `/category/${parseRelativePathSlug(hits.slug)}`,
                    )
                  }
                >
                  <Point
                    to={`/category/${parseRelativePathSlug(hits.slug)}`}
                    href={`/category/${parseRelativePathSlug(hits.slug)}`}
                  >
                    {hits.name}
                  </Point>
                </PointWrap>
              )}

              {newest && (
                <PointWrap
                  isActive={
                    this.props.currentPath ===
                    `/category/${parseRelativePathSlug(newest.slug)}`
                  }
                  onClick={() =>
                    this.checkCurrentPage(
                      `/category/${parseRelativePathSlug(newest.slug)}`,
                    )
                  }
                >
                  <Point
                    to={`/category/${parseRelativePathSlug(newest.slug)}`}
                    href={`/category/${parseRelativePathSlug(newest.slug)}`}
                  >
                    {newest.name}
                  </Point>
                </PointWrap>
              )}

              {collections && (
                <PointWrap
                  isActive={
                    this.props.currentPath ===
                    `/category/${parseRelativePathSlug(collections.slug)}`
                  }
                  onClick={() =>
                    this.checkCurrentPage(
                      `/category/${parseRelativePathSlug(collections.slug)}`,
                    )
                  }
                >
                  <Point
                    to={`/category/${parseRelativePathSlug(collections.slug)}`}
                    href={`/category/${parseRelativePathSlug(
                      collections.slug,
                    )}`}
                  >
                    {collections.name}
                  </Point>
                </PointWrap>
              )}

              {sale && (
                <PointWrap
                  isActive={
                    this.props.currentPath ===
                    `/category/${parseRelativePathSlug(sale.slug)}`
                  }
                  onClick={() =>
                    this.checkCurrentPage(
                      `/category/${parseRelativePathSlug(sale.slug)}`,
                    )
                  }
                >
                  <Point
                    to={`/category/${parseRelativePathSlug(sale.slug)}`}
                    href={`/category/${parseRelativePathSlug(sale.slug)}`}
                  >
                    {sale.name}
                  </Point>
                </PointWrap>
              )}
            </Important>

            <Inner>
              {orderBy(this.props.categories, (o) => parseInt(o.priority, 10), [
                'asc',
              ]).map((el) =>
                parseInt(el.parentID, 10) ===
                  parseInt(this.state.parentid, 10) &&
                Boolean(el.status) &&
                parseInt(el.id, 10) !== 298 &&
                parseInt(el.id, 10) !== 299 &&
                parseInt(el.id, 10) !== 300 &&
                parseInt(el.id, 10) !== 131 ? (
                  <PointWrap
                    isActive={
                      this.props.currentPath ===
                      `/category/${parseRelativePathSlug(el.slug)}`
                    }
                    key={`categoryPoint_${el.id}`}
                    onClick={() =>
                      this.checkCurrentPage(
                        `/category/${parseRelativePathSlug(el.slug)}`,
                      )
                    }
                  >
                    <Point
                      to={`/category/${parseRelativePathSlug(el.slug)}`}
                      href={`/category/${parseRelativePathSlug(el.slug)}`}
                    >
                      {el.name}
                    </Point>
                  </PointWrap>
                ) : (
                  ''
                ),
              )}
            </Inner>
            <SocialLinks>
              <Vk text="Vkontakte" variant="black" />
              {/* <Facebook text="Facebook" variant="black" />
              <Instagram text="Instagram" variant="black" /> */}
            </SocialLinks>
          </>
        ) : (
          <Preloader />
        )}
      </>
    );
  }
}

export default connect(null, { changeCategory })(List);
