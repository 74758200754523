import React, { useMemo, memo } from 'react';
import styled, { css } from 'styled-components';

import { useWindowSize, getCurrentBannerAR, getBannerUrl } from 'Utils';

const Picture = styled.picture`
  position: relative;

  img {
    display: block;
    border-radius: 35px;
    width: 100%;

    @media (min-width: 1200px) {
      ${({ width, height, isHorizontal }) =>
        isHorizontal
          ? css`
              width: ${width - 200}px;
            `
          : css`
              height: ${height - 139}px;
              width: auto;
            `};
    }

    @media (max-width: 1200px) {
      border-radius: 25px;
    }
  }
`;

const Source = styled.source.attrs(({ urls }) => ({
  srcSet: `${urls.lcd}, ${urls.retina} 2x`,
}))``;

const DesktopAdaptivePicture = ({
  name,
  width,
  height,
  aspectRatio,
  bannerExtension,
  renderCb,
}) => {
  const banner = useMemo(
    () => getBannerUrl(name, bannerExtension, width, aspectRatio),
    [width, aspectRatio],
  );
  return (
    <Picture
      height={height}
      width={width}
      isHorizontal={banner.aspectRatio - aspectRatio > 0.1}
      onLoad={renderCb}
    >
      <Source urls={banner} />
      <img src={banner.lcd} alt="banner" />
    </Picture>
  );
};

export default memo(DesktopAdaptivePicture);
