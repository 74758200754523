function enableBlockScroll(elementDOM, handler) {
  if (elementDOM) {
    if ('onwheel' in document) {
      elementDOM.addEventListener('wheel', handler);
    }
  }
}

function disableBlockScroll(elementDOM, handler) {
  if (elementDOM) {
    if ('onwheel' in document) {
      // IE9+, FF17+, Ch31+
      elementDOM.removeEventListener('wheel', handler);
    }
  }
}

function boot(isActive, elementDOM, handler) {
  if (isActive) {
    enableBlockScroll(elementDOM, handler);
  } else {
    disableBlockScroll(elementDOM, handler);
  }
}

export default function addEventWheel(isActive, elementDOM, handler) {
  if (handler && elementDOM) {
    boot(isActive, elementDOM, handler);
  }
}
