import { createActions } from 'redux-actions';

export const { themeSwitched } = createActions('THEME_SWITCHED');

export const setDefaultTheme = () => themeSwitched('default');

export const setDarkTheme = () => themeSwitched('dark');

export const toggleTheme = () => (getState) => {
  const { current } = getState().theme;

  const newTheme = current === 'default' ? 'dark' : 'default';

  return themeSwitched(newTheme);
};
