import React from 'react';
import styled from 'styled-components/macro';

import ListProducts from './ListProducts';
import { EmptyButton as UIEmptyButton, ButtonLink as UIButton } from 'UI';
import { cartAllPositions, cartAmountPrice, addEventWheel } from 'Utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 17px;
  margin: 0;
  padding: 20px;

  @media screen and (min-width: 1200px) {
    padding: 40px;
    font-size: 21px;
  }
`;

const ListWrapper = styled.div`
  height: 100%;
`;

const BottomInfo = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  border-top: 2px solid ${({ theme }) => theme.colors.primary};

  @media screen and (min-width: 1200px) {
    padding-left: 0;
    margin-left: 40px;
    padding-right: 40px;
    padding-top: 35px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Name = styled.span`
  font-size: 13px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    font-size: 19px;
  }
`;

const Positions = styled.span`
  font-size: 12px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

const Price = styled.span`
  font-size: 24px;
  font-weight: 600;

  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }
`;

const Buttons = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;

  @media screen and (min-width: 1200px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const EmptyButton = styled(UIEmptyButton)`
  display: ${(props) => (props.checkout ? 'inline-block' : 'none')};
  flex-basis: 100%;

  @media screen and (min-width: 1200px) {
    display: inline-block;
  }
`;

const Button = styled(UIButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;

  @media screen and (min-width: 1200px) {
    margin-left: 30px;
  }
`;

const ListScroll = styled.div`
  max-height: ${(props) => props.containerHeight}px;
  overflow-y: auto;
  height: 100%;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export default class List extends React.PureComponent {
  state = {
    occupiedSpace: 0,
    windowHeight: window.innerHeight,
  };

  title = null;

  bottom = null;

  componentDidMount() {
    this.calulateAvailableHeight();
    addEventWheel(true, this.title, this.disableScroll);
    addEventWheel(true, this.bottom, this.disableScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.occupiedSpace === 0 && this.state.occupiedSpace === 0) {
      this.calulateAvailableHeight();
    }
  }

  componentWillUnmount() {
    addEventWheel(false, this.title, this.disableScroll);
    addEventWheel(false, this.bottom, this.disableScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowHeight: window.innerHeight });
  };

  disableScroll = (e) => {
    e.preventDefault();
  };

  calulateAvailableHeight = () => {
    this.setState({
      occupiedSpace:
        this.title.offsetHeight +
        this.bottom.offsetHeight +
        this.props.headerHeight,
    });
  };

  render() {
    return (
      <Wrapper>
        <ListWrapper>
          <Title ref={(el) => (this.title = el)}>Ваша корзина</Title>

          {this.state.occupiedSpace !== 0 && (
            <ListScroll
              containerHeight={
                this.state.windowHeight - this.state.occupiedSpace
              }
              ref={(el) => this.props.setScrollableContainer(el)}
            >
              <ListProducts
                cart={this.props.cart}
                onChangeQuantity={(quantity) => {
                  this.props.onChangeQuantity(quantity);
                }}
                onDelete={this.props.onDelete}
                allowEdit
              />
            </ListScroll>
          )}
        </ListWrapper>

        <BottomInfo ref={(el) => (this.bottom = el)}>
          <Info>
            <Name>Итого:</Name>

            <Summary>
              <Price>{`${cartAmountPrice(this.props.cart)} ₽`}</Price>

              <Positions>
                {`${cartAllPositions(this.props.cart)} позиции`}
              </Positions>
            </Summary>
          </Info>

          <Buttons>
            {this.props.currentPath === '/checkout' ? (
              <>
                <EmptyButton checkout onClick={this.props.onClose}>
                  Обратно к оформлению
                </EmptyButton>
              </>
            ) : (
              <>
                <EmptyButton onClick={this.props.onClose}>
                  Продолжить покупки
                </EmptyButton>

                <Button
                  to="/checkout"
                  href="/checkout"
                  onClick={() => {
                    if (window.ga) {
                      window.ga('send', {
                        hitType: 'event',
                        eventCategory: 'Popup: Cart',
                        eventAction: 'go to order',
                      });
                    }
                  }}
                >
                  Оформить заказ
                </Button>
              </>
            )}
          </Buttons>
        </BottomInfo>
      </Wrapper>
    );
  }
}
