import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Link as RLink, withRouter } from 'react-router-dom';

import { parseRelativePathSlug } from 'Utils';
import {
  subCategoriesDataSelector,
  subCategoriesSelector,
  isDefaultThemeSelector,
} from 'common/selectors';
import { changeSubCategory } from 'common/actions';

import getMainCategoryPhoto from './lib/getMainCategoryPhoto';
import HorizontalScroll from '../HorizontalScroll';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.color7};

  @media screen and (min-width: 1200px) {
    margin: 0;
    border-top: none;
  }
`;

const Photo = styled.div`
  width: 102px;
  height: 102px;
  background-color: ${({ theme }) => theme.colors.color5};
  border-radius: 20px;
  background-image: ${(props) => (props.src ? `url(${props.src})` : 'none')};
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  margin: 0 auto;
  margin-bottom: 9px;
  transition: all 0.18s;

  @media screen and (min-width: 1200px) {
    width: 160px;
    height: 160px;
    margin-bottom: 15px;
  }
`;

const Name = styled.span`
  font-size: 10px;
  font-weight: 500;
  display: block;
  text-align: center;
  max-width: 102px;
  transition: all 0.18s;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    width: calc(100% + 23px);
    height: 100%;
    height: calc(100% + 9px);
    transform: translate(-50%, -50%) scale(0.3);
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.primary};
    z-index: -1;
    opacity: 0;
    transition: all 0.18s;
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    max-width: 180px;

    &:after {
      display: none;
    }
  }
`;

const Category = styled(RLink)`
  text-decoration: none;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  transition: all 0.18s;

  &:first-child {
    /* padding-left: 40px; */
    padding-left: 0;
  }

  &:last-child {
    /* padding-right: 40px; */
    padding-right: 0;
  }

  ${Name} {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.color1 : theme.colors.color2};

    &:after {
      opacity: ${(props) => (props.isActive ? 1 : 0)};
      transform: translate(-50%, -50%)
        scale(${(props) => (props.isActive ? 1 : 0.3)});
    }
  }

  &:hover {
    ${Photo} {
      opacity: ${(props) => (props.isActive ? '1' : '0.7')};
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;

    ${Name} {
      color: ${({ theme, isActive }) =>
        isActive ? theme.colors.primary : theme.colors.color2};
    }
  }
`;

const SubCategories = (props) => {
  const dispatch = useDispatch();
  const { subCategoryId, categoryId } = useSelector(subCategoriesDataSelector);
  const subCategories = useSelector(subCategoriesSelector);
  const isDefaultTheme = useSelector(isDefaultThemeSelector);

  return (
    <Wrapper>
      <HorizontalScroll
        isDefaultTheme={isDefaultTheme}
        onChangeData={props.parentCategorySlug}
      >
        {subCategories.length > 0 && (
          <Category
            to=""
            key="detailMenuSub_all"
            onClick={(e) => {
              e.preventDefault();
              if (window.ga) {
                window.ga('send', {
                  hitType: 'event',
                  eventCategory: 'DetailMenu: Sub Categories',
                  eventAction: 'click',
                  eventLabel: `ВСЕ | Parent: ${props.parentCategorySlug}`,
                });
              }
              if (subCategoryId !== 0) {
                dispatch(changeSubCategory(0));
                e.preventDefault();
                props.history.push(`/category/${props.parentCategorySlug}`);
              }
            }}
            isActive={subCategoryId === 0}
          >
            <Photo src={getMainCategoryPhoto(categoryId)} />

            <Name>Все</Name>
          </Category>
        )}
        {subCategories.map((category) => (
          <Category
            to={`/category/${parseRelativePathSlug(category.slug)}`}
            href={`/category/${parseRelativePathSlug(category.slug)}`}
            key={`detailMenuSub_${category.id}`}
            isActive={subCategoryId === category.id}
            draggable={false}
            onClick={() => {
              if (window.ga) {
                window.ga('send', {
                  hitType: 'event',
                  eventCategory: 'DetailMenu: Sub Categories',
                  eventAction: 'click',
                  eventLabel: `${category.name} | ${parseRelativePathSlug(
                    category.slug,
                  )}`,
                });
              }
              dispatch(changeSubCategory(category.id));
            }}
          >
            <Photo src={category.photo} />

            <Name>{category.name}</Name>
          </Category>
        ))}
      </HorizontalScroll>
    </Wrapper>
  );
};

export default withRouter(SubCategories);
