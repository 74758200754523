import React, { useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import qs from 'qs';
import * as Sentry from '@sentry/browser';

import { Button, Modal, Input, MainTitle, SuccessMessage } from 'UI';
import { validateEmail } from 'Utils';

import { resetPassword } from 'api/modals';

const sendError = (extra, title) => {
  let eventID = false;

  Sentry.withScope((scope) => {
    scope.setExtras(extra);
    scope.setTag('page', 'forget_password_modal');
    scope.setTag('individual_errors', 'true');
    eventID = Sentry.captureEvent({
      message: title || 'Modal->ForgetPassword',
    });
  });

  return eventID;
};

const Title = styled(MainTitle)`
  text-align: center;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;

  @media screen and (min-width: 1200px) {
    margin-top: 60px;
  }
`;

const Content = styled.div`
  margin-top: 30px;

  @media screen and (min-width: 1200px) {
    margin-top: 50px;
  }
`;

export default ({ isOpen, onClose, openingPoint }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onChangeEmail = useCallback((e) => setEmail(e.value), []);

  const restorePassword = useCallback(async () => {
    if (loading) return false;

    if (!email || !validateEmail(email)) {
      alert('Заполните поле Email');
      return false;
    }

    setLoading(true);
    const sendData = qs.stringify({ email });

    try {
      const response = await resetPassword(sendData);

      const { data } = response;

      if (data && data.result) {
        if (window.ga) {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'Personal',
            eventAction: 'Forget Password',
            eventLabel: 'success',
          });
        }

        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      let data = {};

      if (error.response) {
        data = error.response.data;
      }

      if (data.error === '0x0000404') {
        alert('Пользователя с таким E-Mail не найдено');
      } else {
        if (window.jivo_api) {
          window.jivo_api.open();
        }

        const sentryID = sendError(
          {
            sendData,
            errorResponse: error.response,
            errorCode: data.error || '',
            errorMessage: data.message || '',
          },
          'Forget Password Modal Fetch Error',
        );

        alert(
          `Ошибка #10012 | ${sentryID}. Напишите нам в чат и сообщите номера ошибок.`,
        );
      }
      setLoading(false);

      console.log(error);
    }

    return false;
  }, [email, loading, success]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      openingPoint={openingPoint}
      isCustomUpdateComponent
    >
      {success ? (
        <SuccessMessage
          msg="Письмо с инструкциями по восстановлению пароля было отправлено на
        указанный email"
        />
      ) : (
        <>
          <Title>Забыли пароль?</Title>
          <Description>
            Если вы не помните свой пароль, введите адрес электронной почты, и
            мы отправим письмо с инструкциями по восстановлению
          </Description>

          <Content>
            <Input label="Email" onChange={onChangeEmail} />
          </Content>

          <ButtonWrap>
            <Button
              padding="17px 70px"
              paddingMobile="12px 35px"
              onClick={restorePassword}
            >
              Восстановить пароль
            </Button>
          </ButtonWrap>
        </>
      )}
    </Modal>
  );
};
