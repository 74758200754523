import {
  DEFAULT_THEME_TOGGLE_HOUR,
  DARK_THEME_TOGGLE_HOUR,
} from '../constants';

const isNightTime = () => {
  const currentHour = new Date().getHours();

  return (
    currentHour >= DARK_THEME_TOGGLE_HOUR ||
    currentHour < DEFAULT_THEME_TOGGLE_HOUR
  );
};

export default isNightTime;
