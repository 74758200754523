import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Root = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
`;

const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const Element1 = styled.div`
  left: 6px;
  animation: ${ldsEllipsis1} 0.6s infinite;
`;

const Element2 = styled.div`
  left: 6px;
  animation: ${ldsEllipsis2} 0.6s infinite;
`;

const Element3 = styled.div`
  left: 26px;
  animation: ${ldsEllipsis2} 0.6s infinite;
`;

const Element4 = styled.div`
  left: 45px;
  animation: ${ldsEllipsis3} 0.6s infinite;
`;

const Preloader = () => (
  <Wrapper>
    <Root>
      <Element1 />
      <Element2 />
      <Element3 />
      <Element4 />
    </Root>
  </Wrapper>
);

export default memo(Preloader);
