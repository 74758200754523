import styled, { css } from 'styled-components/macro';

import { arrow } from '../icons';

export const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
`;

export const Slide = styled.div`
  height: 100%;
  display: block !important;
`;

export const SlideInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Photo = styled.img`
  max-width: 100%;
  min-height: 100px;
`;

export const Arrow = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 18px;
  width: 54px;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 18px 50%;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    background-position: 25px 50%;
  }
`;

export const ArrowLeft = styled(Arrow)`
  left: 0;
  transform: rotate(180deg) translateX(54px);
  display: ${(props) => (props.isVisible ? 'inherit' : 'none')};
`;

export const ArrowRight = styled(Arrow)`
  right: 0;
  transform: translateX(54px);
  display: ${(props) => (props.isVisible ? 'inherit' : 'none')};
`;

export const Gallery = styled.div`
  width: 100%;
  //height: ${(props) => (props.size === 'similar' ? '370px' : '140px')};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 1200px) {
    &:hover {

      ${ArrowLeft} {
        transform: rotate(180deg) translateX(0);
      }

      ${ArrowRight} {
        transform: translateX(0);
      }
    }
  }

  // @media screen and (min-width: 1200px) {
  //   height: ${(props) => (props.size === 'similar' ? '370px' : '480px')};
  // }
  //
  // @media screen and (min-width: 1800px) {
  //   height: ${(props) => (props.size === 'similar' ? '370px' : '600px')};
  // }
  //
  // @media screen and (min-width: 2350px) {
  //   height: ${(props) => (props.size === 'similar' ? '370px' : '800px')};
  // }
  //
  // @media screen and (min-width: 3500px) {
  //   height: ${(props) => (props.size === 'similar' ? '370px' : '900px')};
  // }
`;

export const GalleryWrapper = styled.div``;

export const Wrapper = styled.div`
  ${(props) =>
    props.typeRender === 'retailrocket'
      ? css`
          width: 135px;

          @media screen and (min-width: 1200px) {
            width: 370px;
          }
        `
      : css`
          width: 50%;
          margin-bottom: 20px;

          &:nth-child(2n + 1) {
            ${GalleryWrapper} {
              border-right: none;
            }
          }

          @media screen and (min-width: 1200px) {
            width: 33.33333333%;

            &:nth-child(3n + 1) {
              ${GalleryWrapper} {
                border-right: none;
              }
            }

            &:nth-child(3n + 2) {
              ${GalleryWrapper} {
                border-right: none;
              }
            }
          }
        `};
`;
