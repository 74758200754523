import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { productsSelector, themeSelector } from 'common/selectors';

import LinkWithIcon from '../LinkWithIcon';

import renderCartAmount from './renderCartAmount';

const LinksMobileWrap = styled.div`
  display: flex;
  flex-basis: 33.33333%;
  justify-content: flex-end;

  @media screen and (min-width: 1200px) {
    display: none;
  }

  /* transition: all 0.18s;
  visibility: ${(props) => (props.isPopupOpen ? 'hidden' : 'visibility')};
  opacity: ${(props) => (props.isPopupOpen ? 0 : 1)}; */
`;

const LinksMobile = (props) => {
  const { isSearchOpen, isPopupOpen, isCartOpen, onChangePopup } = props;
  const products = useSelector(productsSelector);
  const theme = useSelector(themeSelector);

  return (
    <LinksMobileWrap>
      <LinkWithIcon
        to="/search"
        marginRight={45}
        iconName="Search"
        iconWidth="21px"
        isActive={isSearchOpen}
        strokeColor={theme.colors.color2}
        isPopupOpen={isPopupOpen}
        hideOnActiveMobile
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('search');
        }}
      />

      <LinkWithIcon
        to="/cart"
        iconName="Cart"
        iconWidth="24px"
        isActive={isCartOpen}
        strokeColor={theme.colors.color2}
        isPopupOpen={isPopupOpen}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('cart');
        }}
      >
        {renderCartAmount(products)}
      </LinkWithIcon>
    </LinksMobileWrap>
  );
};

export default LinksMobile;
