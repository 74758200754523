import React from 'react';
import styled from 'styled-components/macro';

import { Button, Modal } from '../UI';

import Text from '../Organization/Text';

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 31px;
  font-weight: 700;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
`;

const Info = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
`;

export default class VouchersInfo extends React.Component {
  componentDidMount() {}

  render() {
    const { isOpen, onClose, openingPoint } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose} openingPoint={openingPoint}>
        <>
          <Title>Важная информация</Title>
          <Description>
            Ознакомьтесь с правилами приобретения ваучеров
          </Description>

          <Info>
            <Text />
          </Info>

          <ButtonWrap>
            <Button padding="17px 70px" onClick={onClose}>
              Всё понятно
            </Button>
          </ButtonWrap>
        </>
      </Modal>
    );
  }
}
