import React from 'react';
import styled, { css } from 'styled-components/macro';

const Svg = styled.svg`
  margin: ${(props) => (props.margin ? props.margin : '')};
  width: 20px;

  @media screen and (min-width: 1200px) {
    width: ${(props) => props.width};
  }
`;

const Path = styled.path`
  fill: none;
  stroke: ${({ theme }) => theme.colors.color2};
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
`;

const AnimatePath = styled(Path)`
  stroke-dasharray: ${(props) => props.dashArray};
  stroke-dashoffset: ${(props) => props.dashOffset};
  stroke: ${({ theme }) => theme.colors.primary};
  transition: stroke-dashoffset 0.8s;

  ${(props) =>
    props.isHover &&
    css`
      stroke-dashoffset: ${(propsHovered) => propsHovered.dashOffsetOnHover};
    `};
`;

const Line = styled.line`
  fill: none;
  stroke: ${({ theme }) => theme.colors.color2};
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
`;

const AnimateLine = styled(Line)`
  stroke-dasharray: ${(props) => props.dashArray};
  stroke-dashoffset: ${(props) => props.dashOffset};
  stroke: ${({ theme }) => theme.colors.primary};
  transition: stroke-dashoffset 0.8s;

  ${(props) =>
    props.isHover &&
    css`
      stroke-dashoffset: ${(propsHovered) => propsHovered.dashOffsetOnHover};
    `};
`;

const Polyline = styled.polyline`
  fill: none;
  stroke: ${({ theme }) => theme.colors.color2};
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
`;

const AnimatePolyline = styled(Polyline)`
  stroke-dasharray: ${(props) => props.dashArray};
  stroke-dashoffset: ${(props) => props.dashOffset};
  stroke: ${({ theme }) => theme.colors.primary};
  transition: stroke-dashoffset 0.8s;

  ${(props) =>
    props.isHover &&
    css`
      stroke-dashoffset: ${(propsHovered) => propsHovered.dashOffsetOnHover};
    `};
`;

export default class TicketsIcon extends React.PureComponent {
  render() {
    return (
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsЧlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20.9 25.9"
        xmlSpace="preserve"
        width={this.props.width}
        margin={this.props.margin}
      >
        <Line x1="7.1" y1="17.5" x2="11.3" y2="13.2" />

        <AnimateLine
          dashArray="7"
          dashOffset="7"
          dashOffsetOnHover="0"
          isHover={this.props.isHover}
          x1="7.1"
          y1="17.5"
          x2="11.3"
          y2="13.2"
        />

        <Line x1="10.8" y1="17.1" x2="13" y2="14.9" />

        <AnimateLine
          dashArray="4"
          dashOffset="4"
          dashOffsetOnHover="8"
          isHover={this.props.isHover}
          x1="10.8"
          y1="17.1"
          x2="13"
          y2="14.9"
        />

        <Line x1="8.8" y1="19.2" x2="9.2" y2="18.8" />

        <AnimateLine
          dashArray="1"
          dashOffset="1"
          dashOffsetOnHover="2"
          isHover={this.props.isHover}
          x1="8.8"
          y1="19.2"
          x2="9.2"
          y2="18.8"
        />

        <Line x1="5.8" y1="7.5" x2="5.4" y2="10.8" />

        <AnimateLine
          dashArray="4"
          dashOffset="4"
          dashOffsetOnHover="0"
          isHover={this.props.isHover}
          x1="5.8"
          y1="7.5"
          x2="5.4"
          y2="10.8"
        />

        <Line x1="8.2" y1="7.7" x2="8.15" y2="8.2" />

        <AnimateLine
          dashArray="1"
          dashOffset="1"
          dashOffsetOnHover="2"
          isHover={this.props.isHover}
          x1="8.2"
          y1="7.7"
          x2="8.15"
          y2="8.2"
        />

        <Line x1="2.7" y1="4.7" x2="10.4" y2="5.6" />

        <AnimateLine
          dashArray="8"
          dashOffset="8"
          dashOffsetOnHover="0"
          isHover={this.props.isHover}
          x1="2.7"
          y1="4.7"
          x2="10.4"
          y2="5.6"
        />

        <Polyline points="12.4,4 12.6,2 10.1,3.3 7.7,1.5 5.2,2.8 3,1 1.6,14.7 " />

        <AnimatePolyline
          dashArray="28"
          dashOffset="28"
          dashOffsetOnHover="56"
          isHover={this.props.isHover}
          points="12.4,4 12.6,2 10.1,3.3 7.7,1.5 5.2,2.8 3,1 1.6,14.7 "
        />

        <Path
          d="M15.6,17.1L7.8,25l-1.8-1.8c0,0,1.6-1.7,0.1-3.3C4.4,18.4,2.7,20,2.7,20l-1.8-1.8l7.9-7.9
	L13,6.1l0.6,2.8l2.7,0.5l0.7,2.9l2.8,0.6l-2.6,2.6l-5.3-5.3"
        />

        <AnimatePath
          dashArray="64"
          dashOffset="64"
          dashOffsetOnHover="128"
          isHover={this.props.isHover}
          d="M15.6,17.1L7.8,25l-1.8-1.8c0,0,1.6-1.7,0.1-3.3C4.4,18.4,2.7,20,2.7,20l-1.8-1.8l7.9-7.9
	L13,6.1l0.6,2.8l2.7,0.5l0.7,2.9l2.8,0.6l-2.6,2.6l-5.3-5.3"
        />
      </Svg>
    );
  }
}
