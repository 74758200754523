import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/browser';

import qs from 'qs';
import { validateEmail } from 'Utils';
import { grayArrow, darkGrayArrow } from 'UI/icons';
import { isDefaultThemeSelector } from 'common/selectors';

import { Input, Checkbox, Button as UIButton, Radiobutton } from '../../UI';

const Title = styled.h2`
  margin-top: 10px;
  font-size: 18px;
  font-weight: 300;

  @media screen and (min-width: 1200px) {
    font-size: 31px;
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;

const Form = styled.form`
  margin-top: 40px;

  @media screen and (min-width: 1200px) {
    margin-top: 60px;
  }
`;

const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    margin-top: 70px;
  }
`;

const Button = styled(UIButton)`
  text-align: center;
`;

// const ButtonReset = styled.button`
//   border: none;
//   background: none;
//   padding: 0;
//   margin: 0;
//   color: #656565;
//   font-size: 15px;
//   font-weight: 500;
//   flex-basis: 50%;
//   text-align: center;
//   outline: none;
//   cursor: pointer;
// `;

const Auth = styled.div`
  padding: 0 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.color4};
`;

// const Register = styled.div`
//   padding: 40px;
//   padding-bottom: 20px;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   min-height: 35%;
// `;

// const MiniTitle = styled.h4`
//   font-size: 15px;
//   font-weight: 500;
//   margin: 0;
//   margin-bottom: 40px;
// `;

const BackWrap = styled.div`
  padding: 0 40px;
  margin-top: 10px;
`;

const Back = styled.span`
  position: relative;
  cursor: pointer;
  transition: all 0.18s;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.color11};

  &:before {
    content: '';
    position: absolute;
    left: -13px;
    top: 0px;
    width: 6px;
    height: 12px;
    background-image: url(${({ isDefaultTheme }) =>
      isDefaultTheme ? grayArrow : darkGrayArrow});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: all 0.18s;
  }

  &:hover {
    &:before {
      left: -20px;
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

const Genders = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Gender = styled.div`
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

const Info = styled.div`
  margin-bottom: 40px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 100px;
  }
`;


class PopupLogin extends React.Component {
  state = {
    gender: '',
    readPrivacy: false,
    loading: false,
  };

  sendError = (extra, title) => {
    let eventID = false;

    Sentry.withScope((scope) => {
      scope.setExtras(extra);
      scope.setTag('page', 'popup_register');
      scope.setTag('individual_errors', 'true');
      eventID = Sentry.captureEvent({
        message: title || 'Popup->Personal->Register',
      });
    });

    return eventID;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.gender) {
      alert('Выберите пол');
      return false;
    }

    const name = this.name.input.value;
    const secondName = this.secondName.input.value;
    const email = this.email.input.value;
    const password = this.password.input.value;
    const passwordConfirm = this.passwordConfirm.input.value;

    if (!name) {
      alert('Заполните поле Имя');
      return false;
    }

    if (!secondName) {
      alert('Заполните поле Фамилия');
      return false;
    }

    if (!email || !validateEmail(email)) {
      alert('Заполните поле Email');
      return false;
    }

    if (!password) {
      alert('Заполните поле Пароль');
      return false;
    }

    if (!passwordConfirm) {
      alert('Заполните поле Повторите пароль');
      return false;
    }

    if (password !== passwordConfirm) {
      alert('Пароли не совпадают!');
      return false;
    }

    if (!this.state.readPrivacy) {
      alert('Вы не прочитали условия Обработки персональных данных');
      return false;
    }

    this.setState({ loading: true }, () => {
      const userLogin = {
        email,
        password,
        name,
        secondname: secondName,
        subscribe: false,
        gender: this.state.gender,
      };

      this.props.apiInstance
        .put('/register', qs.stringify(userLogin), {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then((res) => {
          const { data } = res;

          const result = JSON.parse(JSON.stringify(data));
          if (result && result.result && result.user && result.user.token) {
            if (window.ga) {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'Personal',
                eventAction: 'Register',
                eventLabel: 'success',
              });
            }

            alert('Спасибо за регистрацию!');
            this.props.onLogin(data.user);
            this.props.onChangeTab();
          } else {
            if (window.jivo_api) {
              window.jivo_api.open();
            }

            const sentryID = this.sendError(
              {
                sendData: userLogin,
                response: res,
                responseData: data,
              },
              'Popup Register Result Error',
            );

            alert(
              `Ошибка #10007 | ${sentryID}. Напишите нам в чат и сообщите номера ошибок.`,
            );
          }
        })
        .catch((error) => {
          let data = {};

          if (error.response) {
            data = error.response.data;
          }

          if (data.error === '0x0000020') {
            alert(
              'Такой Email уже зарегистрирован, вы можете восстановить пароль',
            );
          } else {
            if (window.jivo_api) {
              window.jivo_api.open();
            }

            const sentryID = this.sendError(
              {
                sendData: userLogin,
                errorResponse: error.response,
                errorCode: data.error || '',
                errorMessage: data.message || '',
              },
              'Popup Register Fetch Error',
            );

            alert(
              `Ошибка #10008 | ${sentryID}. Напишите нам в чат и сообщите номера ошибок.`,
            );

            console.log(error, 'error');
          }
        });
    });
  };

  render() {
    const { isDefaultTheme } = this.props;
    return (
      <>
        <BackWrap>
          <Back
            onClick={() => this.props.onChangeTab()}
            isDefaultTheme={isDefaultTheme}
          >
            Авторизация
          </Back>
        </BackWrap>
        <Auth>
          <Title>Регистрация</Title>
          <Description>
            Заполните все необходимые данные, чтобы создать личный кабинет и
            получить доступ к специальным предложениям и уникальным функциям
          </Description>

          <Form onSubmit={this.onSubmit}>
            <Info>
              <Genders>
                <Gender>
                  <Radiobutton
                    isActive={this.state.gender === 'm'}
                    onClick={() => this.setState({ gender: 'm' })}
                  >
                    Мужской
                  </Radiobutton>
                </Gender>

                <Gender>
                  <Radiobutton
                    isActive={this.state.gender === 'f'}
                    onClick={() => this.setState({ gender: 'f' })}
                  >
                    Женский
                  </Radiobutton>
                </Gender>
              </Genders>

              <Input
                label="Имя"
                name="name"
                ref={(name) => (this.name = name)}
              />

              <Input
                label="Фамилия"
                name="secondName"
                ref={(secondName) => (this.secondName = secondName)}
              />
            </Info>

            <Input
              label="Email"
              type="email"
              name="email"
              ref={(email) => (this.email = email)}
            />

            <Input
              label="Пароль"
              type="password"
              name="password"
              ref={(password) => (this.password = password)}
            />

            <Input
              label="Повторите пароль"
              type="password"
              name="passwordconfirm"
              ref={(passwordConfirm) =>
                (this.passwordConfirm = passwordConfirm)
              }
            />

            <Checkbox
              isActive={this.state.readPrivacy}
              onClick={() =>
                this.setState({ readPrivacy: !this.state.readPrivacy })
              }
            >
              Я прочел (прочла) условия{' '}
              <a
                href="/personal_information"
                target="_blank"
                className={'pink'}
              >
                Обработки персональных данных
              </a>
            </Checkbox>

            <Buttons>
              <Button type="submit" padding="15px 55px">
                Создать аккаунт
              </Button>
            </Buttons>
          </Form>
        </Auth>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isDefaultTheme: isDefaultThemeSelector(state),
});

export default connect(mapStateToProps)(PopupLogin);
