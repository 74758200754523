import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { productsSelector, themeSelector } from 'common/selectors';

import { Links } from '../styled';
import LinkWithIcon from '../LinkWithIcon';

import renderCartAmount from './renderCartAmount';

const LinksRight = (props) => {
  const { isVisitMuseumOpen, isSearchOpen, isCartOpen, onChangePopup } = props;
  const { t, i18n } = useTranslation();
  const products = useSelector(productsSelector);
  const theme = useSelector(themeSelector);

  return (
    <Links right>
      <LinkWithIcon
        to="/tickets"
        iconName="Tickets"
        iconWidth="23px"
        isActive={isVisitMuseumOpen}
        iconMargin="0 7px 0 0"
        name={t('header:visitMuseum')}
        marginRight={54}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('visitmuseum');
        }}
      />

      <LinkWithIcon
        to="/search"
        marginRight={45}
        iconName="Search"
        strokeColor={theme.colors.color2}
        iconWidth="21px"
        isActive={isSearchOpen}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('search');
        }}
      />

      <LinkWithIcon
        to="/cart"
        iconName="Cart"
        iconWidth="24px"
        strokeColor={theme.colors.color2}
        isActive={isCartOpen}
        isExtraVisible={products.length > 0}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('cart');
        }}
      >
        {renderCartAmount(products)}
      </LinkWithIcon>
    </Links>
  );
};

export default LinksRight;
