export default function validateCards(cards) {
  return cards.filter((el) => {
    const { instockquantity, instock } = el;

    let instockState = instockquantity > 0 && instock;

    if (!instockState) {
      if (el.options) {
        el.options.map((option) => {
          const { instock, instockquantity } = option;

          instockState = instock && instockquantity > 0 ? true : instockState;

          return option;
        });
      }
    }

    return instockState;
  });
}
