import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  padding: 40px 40px;

  @media screen and (min-width: 1200px) {
    padding: 60px 100px;
  }
`;

export default class Content extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.isOpen !== this.props.isOpen &&
      !this.props.isNormalRerenderChildren
    ) {
      return false;
    }

    return true;
  }

  render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}
