export default function isPopupOpen(popups) {
  const filtered = Object.keys(popups).filter(
    popupName => popups[popupName].isOpen,
  );

  if (filtered.length !== 0) {
    return true;
  }

  return false;
}
