import React from 'react';
import styled from 'styled-components';

import {
  facebookColored,
  facebookBlack,
  facebookGrey,
  darkFacebookGrey,
  darkFacebookBlack,
} from '../icons';

const Wrapper = styled.a.attrs(({ variant }) => ({
  'data-variant': variant,
}))`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.87;
  color: ${({ theme }) => theme.colors.color2};
  transition: all 0.18s;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
    div {
      background-image: url(${facebookColored}) !important;
    }
  }

  :active {
    color: ${({ theme }) => theme.colors.primary};
    div {
      background-image: url(${facebookColored}) !important;
    }
  }

  &[data-variant='black'] {
    div {
      background-image: url(${({ theme }) =>
        theme.isDefault ? facebookBlack : darkFacebookBlack});
    }
  }

  &[data-variant='grey'] {
    div {
      background-image: url(${({ theme }) =>
        theme.isDefault ? facebookGrey : darkFacebookGrey});
    }
  }

  @media screen and (max-width: 1200px) {
    font-size: 8px;
    line-height: 3.5;
    margin-right: 20px;
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${facebookColored});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  @media screen and (max-width: 1200px) {
    width: 15px;
    height: 15px;
  }
`;

const Text = styled.span`
  padding-left: 6px;
`;

const Facebook = ({ text, variant, margin }) => (
  <Wrapper
    variant={variant}
    href="https://www.facebook.com/hermitageshop"
    target="_blank"
    style={{ margin }}
  >
    <Icon />
    {text && <Text>{text}</Text>}
  </Wrapper>
);

export default Facebook;
