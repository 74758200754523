import { CATEGORIES_IDS } from '../../../constants';

import books from '../subParentPhotos/books.jpg';
import reproductions from '../subParentPhotos/reproductions.jpg';
import home from '../subParentPhotos/home.jpg';
import clothes from '../subParentPhotos/clothes.jpg';
import jewerly from '../subParentPhotos/jewerly.jpg';
import stationery from '../subParentPhotos/stationery.jpg';
import kids from '../subParentPhotos/kids.jpg';
import sculpture from '../subParentPhotos/sculpture.jpg';
import collections from '../subParentPhotos/collections.jpg';
import graphics from '../subParentPhotos/graphics.jpg';

const {
  BOOKS,
  REPRODUCTIONS,
  HOME,
  CLOTHES,
  JEWERLY,
  STATIONERY,
  KIDS,
  SCULPTURE,
  COLLECTIONS,
  GRAPHICS,
} = CATEGORIES_IDS;

const parentCategories = [
  {
    // books
    id: BOOKS,
    photo: books,
  },
  {
    // reproductions
    id: REPRODUCTIONS,
    photo: reproductions,
  },
  {
    // home/decor
    id: HOME,
    photo: home,
  },
  {
    // clothes and accessories
    id: CLOTHES,
    photo: clothes,
  },
  {
    // jewerly
    id: JEWERLY,
    photo: jewerly,
  },
  {
    // stationery
    id: STATIONERY,
    photo: stationery,
  },
  {
    // kids
    id: KIDS,
    photo: kids,
  },
  {
    // sculpture
    id: SCULPTURE,
    photo: sculpture,
  },
  {
    // collections
    id: COLLECTIONS,
    photo: collections,
  },
  {
    // graphics
    id: GRAPHICS,
    photo: graphics,
  },
];

export default (categoryID) => {
  const searchedCategory = parentCategories.find(
    (category) => category.id === parseInt(categoryID, 10),
  );

  if (!searchedCategory) return;

  return searchedCategory.photo;
};
