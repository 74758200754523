import React from 'react';
import styled from 'styled-components/macro';

import {
  arrowHorizontalScroll as arrow,
  darkArrowHorizontalScroll as darkArrow,
} from './icons';

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-width: 100%;
  overflow: -moz-scrollbars-none;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 0;
  z-index: 5;
  height: 100%;
  width: 50px;
  background: none;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:after {
    content: '';
    position: relative;
    display: block;
    width: 9px;
    height: 18px;
    background-image: url(${({ isDefaultTheme }) =>
      isDefaultTheme ? arrow : darkArrow});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 50% 50%;
    transition: all 0.18s ease;
  }

  &:hover {
    &:after {
      transform: translate(50%, 0);
    }
  }

  @media screen and (min-width: 1200px) {
    width: 150px;
  }
`;

const ArrowPrev = styled(Arrow)`
  left: 0;
  padding-right: 10px;
  transform: rotate(180deg)
    translateX(${(props) => (props.isVisible ? '0%' : '100%')});
  background: linear-gradient(
    90deg,
    ${({ isDefaultTheme }) =>
        isDefaultTheme ? 'rgba(255, 255, 255, 0)' : 'rgba(43, 43, 45, 0)'}
      0%,
    ${({ theme }) => theme.colors.color3} 100%
  );
`;

const ArrowNext = styled(Arrow)`
  right: 0;
  transform: translateX(${(props) => (props.isVisible ? '0%' : '100%')});
  padding-right: 10px;
  background: linear-gradient(
    90deg,
    ${({ isDefaultTheme }) =>
        isDefaultTheme ? 'rgba(255, 255, 255, 0)' : 'rgba(43, 43, 45, 0)'}
      0%,
    ${({ theme }) => theme.colors.color3} 100%
  );
`;

export default class HorizontalScroll extends React.Component {
  contentDOM = null;

  isMouseHolding = false;

  isMouseMoving = false;

  startX;

  scrollLeft = 0;

  constructor() {
    super();

    this.state = {
      // isBlockContent: false,
      isVisiblePrev: false,
      isVisibleNext: false,
    };

    this.blockContent = null;
    this.isBlockContent = false;
  }

  componentDidMount() {
    this.checkVisibleArrows();

    if ('onwheel' in document) {
      // IE9+, FF17+, Ch31+
      this.wrapperDOM.addEventListener('wheel', this.onWheel);
    } else if ('onmousewheel' in document) {
      // устаревший вариант события
      this.wrapperDOM.addEventListener('mousewheel', this.onWheel);
    } else {
      // Firefox < 17
      this.wrapperDOM.addEventListener('MozMousePixelScroll', this.onWheel);
    }

    this.contentDOM.addEventListener('scroll', this.onScroll);

    this.wrapperDOM.addEventListener('mousedown', this.onMouseDown);

    this.wrapperDOM.addEventListener('mouseleave', this.onMouseLeave);

    this.wrapperDOM.addEventListener('mouseup', this.onMouseUp);

    this.wrapperDOM.addEventListener('mousemove', this.onMove);
  }

  componentDidUpdate(prevProps) {
    this.checkVisibleArrows();

    if (prevProps.onChangeData !== this.props.onChangeData) {
      this.contentDOM.scrollLeft = 0;
      this.scrollLeft = this.contentDOM.scrollLeft;
    }
  }

  componentWillUnmount() {
    if ('onwheel' in document) {
      // IE9+, FF17+, Ch31+
      this.wrapperDOM.removeEventListener('wheel', this.onWheel);
    } else if ('onmousewheel' in document) {
      // устаревший вариант события
      this.wrapperDOM.removeEventListener('mousewheel', this.onWheel);
    } else {
      // Firefox < 17
      this.wrapperDOM.removeEventListener('MozMousePixelScroll', this.onWheel);
    }

    this.contentDOM.removeEventListener('scroll', this.onScroll);

    this.wrapperDOM.removeEventListener('mousedown', this.onMouseDown);

    this.wrapperDOM.removeEventListener('mouseleave', this.onMouseLeave);

    this.wrapperDOM.removeEventListener('mouseup', this.onMouseUp);

    this.wrapperDOM.removeEventListener('mousemove', this.onMove);
  }

  onMove = (e) => {
    if (!this.isMouseHolding) {
      return false;
    }

    e.preventDefault();

    this.isMouseMoving = true;

    const x = e.pageX - this.contentDOM.offsetLeft;
    const walk = (x - this.startX) * 1; // scroll-fast
    this.contentDOM.scrollLeft = this.scrollLeft - walk;

    return true;
  };

  onMouseUp = () => {
    setTimeout(() => {
      this.isMouseHolding = false;
      this.isMouseMoving = false;
    }, 0);
  };

  onMouseDown = (e) => {
    e.preventDefault();
    this.isMouseHolding = true;
    this.startX = e.pageX - this.contentDOM.offsetLeft;
    this.scrollLeft = this.contentDOM.scrollLeft;
  };

  onMouseLeave = () => {
    setTimeout(() => {
      this.isMouseHolding = false;
      this.isMouseMoving = false;
    }, 0);
  };

  getDirection = (deltaX, deltaY) => Math.abs(deltaX) > Math.abs(deltaY);

  onWheel = (e) => {
    const isHorizontal = this.getDirection(e.deltaX, e.deltaY);

    if (!isHorizontal) {
      e.preventDefault();

      const currentScrolled =
        this.contentDOM.scrollLeft + this.contentDOM.offsetWidth;

      const walk = e.deltaY - this.contentDOM.offsetLeft;

      this.contentDOM.scrollLeft = this.scrollLeft + walk;

      if (
        this.contentDOM.scrollLeft <= 0 ||
        currentScrolled >= this.contentDOM.scrollWidth
      ) {
        window.scrollTo(0, window.scrollY + walk);
      }
    }

    this.scrollLeft = this.contentDOM.scrollLeft;
  };

  onScroll = () => {
    this.checkVisibleArrows();
  };

  checkVisibleArrows = () => {
    if (this.props.children && this.props.children.length > 0) {
      // check next arrow
      if (
        this.contentDOM.scrollLeft ===
        this.contentDOM.scrollWidth - this.contentDOM.offsetWidth
      ) {
        if (this.state.isVisibleNext) {
          this.setState({
            isVisibleNext: false,
          });
        }
      } else if (!this.state.isVisibleNext) {
        this.setState({
          isVisibleNext: true,
        });
      }

      // check prev arrow
      if (this.contentDOM.scrollLeft === 0) {
        if (this.state.isVisiblePrev) {
          this.setState({
            isVisiblePrev: false,
          });
        }
      } else if (!this.state.isVisiblePrev) {
        this.setState({
          isVisiblePrev: true,
        });
      }
    }
  };

  createScrollStep = (step) => {
    let newStep = step;

    if (step === 'full') {
      newStep = this.contentDOM.offsetWidth;
    }

    switch (step) {
      case 'full':
        newStep = this.contentDOM.offsetWidth;
        break;

      case '-full':
        newStep = -this.contentDOM.offsetWidth;
        break;

      default:
        break;
    }

    this.contentDOM.scroll({
      top: 0,
      left: this.scrollLeft + newStep,
      behavior: 'smooth',
    });

    this.scrollLeft = this.contentDOM.scrollLeft;
  };

  render() {
    const { isDefaultTheme } = this.props;
    return (
      <Wrapper ref={(el) => (this.wrapperDOM = el)}>
        <ArrowPrev
          isDefaultTheme={isDefaultTheme}
          isVisible={this.state.isVisiblePrev}
          onClick={() => this.createScrollStep('-full')}
        />

        <Content ref={(el) => (this.contentDOM = el)} draggable={false}>
          {this.props.children}
        </Content>

        <ArrowNext
          isDefaultTheme={isDefaultTheme}
          isVisible={this.state.isVisibleNext}
          onClick={() => this.createScrollStep('full')}
        />
      </Wrapper>
    );
  }
}
