import { handleAction } from 'redux-actions';

import { isNightTime } from 'Utils';

import { themeSwitched } from './actions';

const initialState = {
  // current: window.location.pathname === '/' ? 'dark' : 'default',
  current: isNightTime() ? 'dark' : 'default',
};

export default handleAction(
  themeSwitched,
  (state, { payload }) => ({ ...state, current: payload }),
  initialState,
);
