import styled from 'styled-components/macro';

const Button = styled.button`
  display: inline-block;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.color6 : theme.colors.primary};
  border: none;
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.color2 : '#fff'};
  padding: ${(props) =>
    props.paddingMobile
      ? props.paddingMobile
      : props.padding
      ? props.padding
      : '15px 25px'};
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  outline: none;
  transition: all 0.18s;

  &:hover {
    background-color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.color6 : '#dc5c9e'};
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    padding: ${(props) => (props.padding ? props.padding : '15px 25px')};
  }
`;

export default Button;
