import styled from 'styled-components/macro';

import { RouteLink as RLink } from '../UI';

import {
  footerArrow,
  footerArrowOpen,
  darkFooterArrow,
  darkFooterArrowOpen,
} from '../UI/icons';

export const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.color1}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.color11};
  font-size: 12px;
  font-weight: 500;
  /* filter: ${(props) => (props.isOpenPopup ? 'blur(1px)' : 'none')}; */
  /* user-select: ${(props) => (props.isOpenPopup ? 'none' : 'initial')}; */
  transition: all 0.35s ease;

  @media screen and (min-width: 1200px) {
    position: fixed;
    z-index: -1;
    height: 336px;
    display: ${(props) => (props.isHiddenDesktop ? 'none' : 'flex')};
  }
`;

export const Title = styled.h5`
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.colors.color11};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 3px;
    background-image: url(${({ isOpen, isDefaultTheme }) =>
      isOpen
        ? isDefaultTheme
          ? footerArrowOpen
          : darkFooterArrowOpen
        : isDefaultTheme
        ? footerArrow
        : darkFooterArrow});
    width: 15px;
    height: 10px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  @media screen and (min-width: 1200px) {
    color: inherit;
    margin-bottom: 20px;

    &:after {
      display: none;
    }
  }
`;

export const SupportTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;

  &:after {
    display: none;
  }
`;

export const Phone = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.color11};
  text-decoration: none;
  margin-bottom: 5px;
  font-size: 12px;
  transition: all 0.18s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (min-width: 1200px) {
    /* font-size: 12px; */
  }
`;

export const Email = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.color11};
  text-decoration: none;
  font-size: 12px;
  transition: all 0.18s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (min-width: 1200px) {
    /* font-size: 12px; */
  }
`;

export const Time = styled.p`
  padding: 0;
  margin: 0;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Navigation = styled.nav``;

export const Link = styled(RLink)`
  color: inherit;
  text-decoration: none;
  display: block;
  transition: color 0.18s;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    color: #e71d85;
  }

  @media screen and (min-width: 1200px) {
    /* font-size: 12px; */
  }
`;

export const FakeLink = styled.span`
  display: block;
  transition: color 0.18s;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.color11};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (min-width: 1200px) {
    /* font-size: 12px; */
  }
`;

export const LinkTop = styled(Link)`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.color11};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LinkBottom = styled(Link)`
  color: #000000;
  font-size: 10px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
    border-bottom: 1px solid transparent;

    &:hover {
      color: #000000;
      border-color: #000000;
    }
  }
`;

export const FakeLinkLinkBottom = styled.span`
  color: ${({ theme }) => theme.colors.color2};
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  @media screen and (min-width: 1200px) {
    /* font-size: 12px; */
    border-bottom: 1px solid transparent;

    &:hover {
      color: ${({ theme }) => theme.colors.color2};
      border-color: ${({ theme }) => theme.colors.color2};
    }
  }
`;

export const Point = styled.div`
  margin-bottom: 20px;
  font-size: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 15px;
  }
`;

export const PointWrap = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 15px;
  }
`;

export const SupportPoint = styled(Point)`
  position: relative;
  padding-left: 12px;
  cursor: pointer;
  transition: all 0.18s;
  color: ${({ theme }) => theme.colors.color11};

  &:before {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: ${({ theme, accent }) =>
      accent ? theme.colors.primary : theme.colors.color8};
    position: absolute;
    left: 0;
    top: 2px;
  }

  @media screen and (min-width: 1200px) {
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const HermitageLogo = styled.img``;

export const HermitageDescription = styled.span`
  font-size: 10px;
  font-weight: 600;
  margin-top: 15px;
`;

export const HermitageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -45px;
    top: 0;
    width: 1px;
    height: 168px;
    background-color: ${({ theme }) => theme.colors.color6};
    display: ${(props) => (props.mobile ? 'none' : 'initial')};
  }
`;

export const BottomWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.color3};
  padding: ${(props) => (props.homePage ? '40px 0 0 0' : '20px 0')};
  border-top: ${({ theme, homePage }) =>
    homePage ? `1px solid ${theme.colors.color6}` : 'none'};
  margin-top: ${(props) => (props.homePage ? '50px' : 0)};
`;

export const TopWrapper = styled.div`
  margin-top: 40px;
  width: 100%;

  @media (max-width: 1200px) {
    background-color: ${({ theme }) => theme.colors.color1};
  }
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: ${(props) => (props.homePage ? 'wrap' : 'nowrap')};

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const SocialLinks = styled.div`
  @media screen and (min-width: 1350px) {
    width: 130px;
  }
  @media screen and (min-width: 1201px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 10px;
  }
`;

export const MobileSocialLinks = styled.div`
  @media screen and (max-width: 1201px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0 50px 0;
  }
`;

export const PointBottom = styled.div`
  text-align: center;
  margin-bottom: 20px;

  @media screen and (min-width: 1200px) {
    text-align: initial;
    margin-right: 25px;
    margin-bottom: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Copyright = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-top: 30px;
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.color2};

  @media screen and (min-width: 1200px) {
    /* font-size: 12px; */
    font-weight: 500;
    margin-top: 15px;
    max-width: 100%;
    text-align: initial;
    color: inherit;
  }
`;

export const Settings = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const RegionWrapper = styled.div`
  margin-right: 15px;
`;

export const SettingsTitle = styled.span`
  display: block;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const Region = styled.div`
  display: flex;
  align-items: center;
`;

export const LanguageWrapper = styled.div``;

export const Language = styled.div`
  display: flex;
  align-items: center;
`;

export const Flag = styled.img`
  max-width: 15px;
  margin-right: 5px;
`;

export const RegionName = styled.span`
  color: ${({ theme }) => theme.colors.color12};
`;

export const LanguageTag = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 5px;
`;

export const LanguageName = styled.span`
  color: ${({ theme }) => theme.colors.color12};
`;

export const Tab = styled.div`
  padding: 20px 22px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.color7};
    display: ${(props) =>
      props.alwaysOpen || props.beforeDisable ? 'none' : 'block'};
  }

  @media screen and (min-width: 1200px) {
    padding: 0;

    &:before {
      display: none;
    }
  }
`;

export const TabContent = styled.div`
  @media screen and (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.4s ease;
    max-height: ${(props) => (props.isOpen ? '500px' : 0)};
    margin-top: ${(props) => (props.isOpen ? '20px' : 0)};
  }
`;
