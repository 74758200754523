import { CART_TIMEOUT_KEY, CART_UPDATE_TIMEOUT } from '../../../constants';

export const setNewUpdateTimestamp = () => {
  const currentTime = Date.now();

  localStorage.setItem(CART_TIMEOUT_KEY, currentTime);
};

export const getLastUpdateTimestamp = () =>
  JSON.parse(localStorage.getItem(CART_TIMEOUT_KEY));

export const checkUpdateRequirement = () => {
  const lastUpdate = getLastUpdateTimestamp();

  if (!lastUpdate) {
    setNewUpdateTimestamp();

    return false;
  }

  const currentTime = Date.now();

  return currentTime - lastUpdate > CART_UPDATE_TIMEOUT;
};
