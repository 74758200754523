import styled, { css } from 'styled-components/macro';
import { Link as RLink } from 'react-router-dom';

import { check, darkCheck } from './icons';

import Input from './Input';
import NumSlider from './NumSlider';
import LoadingRoute from './LoadingRoute';
import Preloader from './Preloader';
import Popup from './Popup';
import Error from './Error';
import Pagination from './Pagination';
import Button from './Button';
import Modal from './Modal';
import EmptyModal from './EmptyModal';
import Stories from './Stories';
import CircleWait from './CircleWait';
import DetailMenu from './DetailMenu';
import HorizontalScroll from './HorizontalScroll';
import ProductCard from './ProductCard';
import RocketRetail from './RocketRetail';
import RouteLink from './RouteLink';
import MobileAdaptivePicture from './MobileAdaptivePicture';
import DesktopAdaptivePicture from './DesktopAdaptivePicture';

import Title2 from './Title2';
import Title4 from './Title4';
import Checkbox from './Checkbox';
import MiniCheckbox from './MiniCheckbox';
import MainTitle from './MainTitle';
import SuccessMessage from './SuccessMessage';
import HamburgerButton from './HamburgerButton';
import {
  DiscountSquare,
  Discount,
  DiscountLine,
  OldPrice,
  NewPrice,
} from './SaleElements';
import Vk from './SocialLinks/vk';
import Facebook from './SocialLinks/facebook';
import Instagram from './SocialLinks/instagram';
import PhotoSlider from './PhotoSlider';

export const Wrapper = styled.main`
  position: relative;
  z-index: 1;
  margin-bottom: 0;

  @media screen and (min-width: 1200px) {
    margin-bottom: ${(props) => (props.footerHeight ? props.footerHeight : 0)};
  }
`;

export const ButtonLink = styled(RLink)`
  display: inline-block;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.color6 : theme.colors.primary};
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.color2 : theme.colors.color1};
  padding: ${(props) =>
    props.paddingMobile
      ? props.paddingMobile
      : props.padding
      ? props.padding
      : '15px 25px'};
  text-decoration: none;
  transition: all 0.18s;

  &:hover {
    background-color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.color6 : '#dc5c9e'};
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    padding: ${(props) => (props.padding ? props.padding : '15px 25px')};
  }
`;

export const EmptyButton = styled.button`
  display: inline-block;
  background-color: transparent;
  border: 2px solid
    ${({ theme, white }) => (white ? theme.colors.color1 : theme.colors.color2)};
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme, white }) =>
    white ? theme.colors.color1 : theme.colors.color2};
  padding: ${(props) => (props.padding ? props.padding : '15px 25px')};
  cursor: pointer;
  outline: none;
  transition: all 0.18s;

  &:hover {
    background-color: ${({ theme, white }) =>
      white ? theme.colors.color1 : theme.colors.color2};
    color: ${({ theme, white }) =>
      white ? theme.colors.color2 : theme.colors.color1};
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

export const EmptyButtonLink = styled(RLink)`
  display: inline-block;
  text-decoration: none;
  background-color: transparent;
  border: 2px solid ${(props) => (props.white ? '#FFF' : '#000000')};
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => (props.white ? '#FFF' : '#363636')};
  padding: ${(props) => (props.padding ? props.padding : '15px 25px')};
  cursor: pointer;
  outline: none;
  transition: all 0.18s;

  &:hover {
    background-color: ${(props) => (props.white ? '#FFF' : '#000000')};
    color: ${(props) => (props.white ? '#363636' : '#fff')};
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

export const Tag = styled(RLink)`
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : theme.colors.color6};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.color1 : theme.colors.color11};
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  word-break: break-word;
  transition: all 0.18s;

  @media screen and (min-width: 1200px) {
    font-size: 14px;
    padding: 7px 18px;

    &:hover {
      color: #fff;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Radiobutton = styled.div`
  font-size: 12px;
  font-weight: ${({ primary }) => (primary ? 700 : 600)};
  position: relative;
  padding-left: 29px;
  color: ${({ primary, theme }) =>
    primary ? theme.colors.primary : theme.colors.color2};
  outline: none;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  user-select: none;
  filter: grayscale(${(props) => (props.isDisabled ? 1 : 0)});
  transition: all 0.18s;

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    border-radius: 100%;
  }

  &:before {
    border: 1px solid
      ${({ theme, isActive }) =>
        isActive ? theme.colors.primary : theme.colors.color8};
    background-color: ${({ theme }) => theme.colors.color4};
    width: 14px;
    height: 14px;
    top: -2px;
    transition: all 0.18s;
  }

  &:after {
    transition: all 0.18s;
    width: 8px;
    height: 8px;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : 'transparent'};
    left: 4px;
    top: 2px;
    transform: ${(props) => (props.isActive ? 'scale(1)' : 'scale(0)')};
  }

  &:hover {
    &:before {
      ${(props) =>
        !props.isDisabled &&
        css`
          border-color: ${({ theme }) => theme.colors.primary};
        `};
    }
  }

  @media screen and (min-width: 1200px) {
    padding-left: 38px;
    font-size: 15px;
    font-weight: ${({ primary }) => (primary ? 700 : 500)};

    &:before {
      width: 20px;
      height: 20px;
      top: -3px;
    }

    &:after {
      width: 12px;
      height: 12px;
      left: 5px;
      top: 2px;
    }
  }
`;

export const Title1 = styled.h1`
  font-size: 21px;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @media screen and (min-width: 1200px) {
    font-size: 50px;
    font-weight: 900;
  }
`;

export const Title3 = styled.h3`
  font-size: 17px;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @media screen and (min-width: 1200px) {
    font-size: 21px;
    font-weight: 600;
  }
`;

export const Title5 = styled.h5`
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;

  @media screen and (min-width: 1200px) {
    font-size: 17px;
  }
`;

export const PriceTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ListCheckMarks = styled.ul`
  padding: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ListCheckMarksPoint = styled.li`
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    background-image: url(${({ theme }) =>
      theme.isDefault ? check : darkCheck});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;

    &:before {
      width: 21px;
      top: 2px;
    }
  }
`;

export const ButtonRounded = styled.button`
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  background-color: #e71d85;
  border-radius: 100px;
  text-decoration: none;
  padding: 15px 37px;
  display: inline-block;
  transition: all 0.18s;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const ButtonLinkRounded = styled(RLink)`
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  background-color: #e71d85;
  border-radius: 100px;
  text-decoration: none;
  padding: 15px 37px;
  display: inline-block;
  transition: all 0.18s;

  &:hover {
    opacity: 0.7;
  }
`;

export {
  Input,
  NumSlider,
  LoadingRoute,
  Popup,
  Preloader,
  Title2,
  Error,
  Pagination,
  Button,
  Stories,
  CircleWait,
  Title4,
  Checkbox,
  MiniCheckbox,
  MainTitle,
  DetailMenu,
  HorizontalScroll,
  Modal,
  EmptyModal,
  ProductCard,
  RocketRetail,
  RouteLink,
  SuccessMessage,
  MobileAdaptivePicture,
  DesktopAdaptivePicture,
  HamburgerButton,
  DiscountSquare,
  Discount,
  DiscountLine,
  OldPrice,
  NewPrice,
  Vk,
  Facebook,
  Instagram,
  PhotoSlider,
};
